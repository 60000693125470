
import React, { useContext, useState } from 'react';
import { getBezierPath } from 'reactflow';
import CanvasContext from '../../../context/CanvasContext';
import styles from './styles/ClassroomCanvasEdge.module.css';
import 'reactflow/dist/style.css';


/**
 * Edge component renders an edge between two nodes in a React Flow diagram.
 * Important for Team 3 to understand how this works
 *
 * @component
 * @param {Object} props - The properties for the Edge component.
 * @param {string} props.id - The unique identifier for the edge.
 * @param {number} props.sourceX - The x-coordinate of the source node.
 * @param {number} props.sourceY - The y-coordinate of the source node.
 * @param {number} props.targetX - The x-coordinate of the target node.
 * @param {number} props.targetY - The y-coordinate of the target node.
 * @param {string} [props.sourcePosition='right'] - The position of the source node.
 * @param {string} [props.targetPosition='left'] - The position of the target node.
 * @param {string} props.source - The id of the source node.
 * @param {string} props.target - The id of the target node.
 * @param {Object} props.data - Additional data associated with the edge.
 *
 * @returns {JSX.Element|null} The rendered edge component.
 */
const ClassroomCanvasEdge = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition = 'right',
    targetPosition = 'left',
    source,
    target,
    data,
}) => {

    /**
     * STATE VARAIABLES AND CONTEXT IMPORTS
     */
    const {
        selectedEdgeId,
        edges,
        onSelectEdge,
        onCanvasClick,
        isGeneratingLogs,
        addEdgeToLog,
        getEdge,
    } = useContext(CanvasContext);
    const [hoveredEdgeId, setHoveredEdgeId] = useState(null);
    const [labelHovered, setLabelHovered] = useState(false);

    const boxColor = data.edgeColor || '#ffffff'


    
    

    /**
     * EDGE PATH LOGIC
     */

    /**
     * Checks if the given edge is currently selected.
     *
     * @param {string} edgeId - The ID of the edge to check.
     * @returns {boolean} True if the edge is selected, false otherwise.
     */
    const isSelectedEdge = (edgeId) => edgeId === selectedEdgeId;

    /** Styles applied to an edge that is selected */
    const selectedEdgeStyle = {
        stroke: (data.edgeColor || '#999'),
        strokeWidth: 2,
    };

    /**
     * Author: Leon Liang
     * Description: Filters edges to find all edges that connect the same two nodes.
     * This includes edges in both directions between the source and target nodes.
     * Example 1: edge1 connecting nodeA->nodeB and edge2 connecting nodeA->nodeB are similar.
     * Example 2: edge2 connecting nodeA->nodeB and edge2 connecting nodeB->nodeA are not similar. edge2 is counted as a "reverse edge".
     *
     * @type {Array}
     */
    const similarEdges = edges.filter(
        (edge) => edge.source === source && edge.target === target
    );

    const reverseEdges = edges.filter(
        (edge) => edge.source === target && edge.target === source && target !== source
    );

    /**
     * Author: Leon Liang
     * Description: Combines similar edges and reverse edges as allEdges.
     * If no edges exist for this pair, return early.
     *
     * @type {Array}
     */
    const allEdges = [...similarEdges, ...reverseEdges];

    if (allEdges.length === 0) {
        return null;
    }

    /**
     * Author: Leon Liang
     * Description: Determine the "first edge" by sorting.
     * This ensures only one Edge component actually renders the visuals.
     */
    // const firstLabel = [...allEdges].sort((a, b) => a.id.localeCompare(b.id))[0];



    /**
     * LOOP constants
     * Defines what a "self-loop" edge is
     * Defines the X (major) and Y (minor) radii of the elliptical of "self-loop" edges
     */
    const isLoopEdge = source === target;
    const radiusY = 50;
    const radiusX = (sourceX - targetX) * 0.6;

    /**
     * Determines whether the edge label groups should be expanded.
     * The labels for similar edges are grouped together and are normally collapsed.
     * Labels are expanded if its corresponding edge/label is hovered over or selected.
     *
     * @type {boolean}
     */
    const labelsExpanded =
        hoveredEdgeId !== null ||
        labelHovered ||
        allEdges.some((edge) => edge.id === selectedEdgeId);

    /**
     * Handles mouse enter event on an edge.
     *
     * @param {string} edgeId - The ID of the edge being hovered.
     */
    const handleMouseEnter = (edgeId) => setHoveredEdgeId(edgeId);

    /** Handles mouse leave event on an edge. */
    const handleMouseLeave = () => setHoveredEdgeId(null);

    /** Handles mouse enter event on a label. */
    const handleLabelMouseEnter = () => setLabelHovered(true);

    /** Handles mouse leave event on a label. */
    const handleLabelMouseLeave = () => setLabelHovered(false);

    /**
     * Handles click event on an edge.
     * If not in log generation mode, selects or deselects the edge.
     * If generating logs, adds the edge to the log's .decisions array.
     *
     * @param {string} edgeId - The ID of the edge being clicked.
     * @param {Object} event - The click event.
     */
    const handleEdgeClick = async (edgeId, event) => {
        event.stopPropagation();

        if (!isGeneratingLogs) {
            if (selectedEdgeId === edgeId) {
                onCanvasClick();
            } else {
                onSelectEdge(edgeId);
            }
        } else {
            const edge = getEdge(edgeId);
            if (edge) {
                addEdgeToLog(edge);
            } else {
                console.error(`Edge not found for edgeId: ${edgeId}`);
            }
        }
    };

    /**
     * Renders the SVG paths for the edges.
     * Handles both loop edges (edges where source and target are the same) and regular edges.
     *
     * @type {Array}
     */
    const edgePaths = similarEdges.map((edge) => {
        let edgePath;

        if (isLoopEdge) {
            /**
             * Constructs a loop path for "self-referential" edges where the source and target nodes are the same (linking nodeA->nodeA).
             * Uses an SVG arc to create a loop.
             */
            const offset = 5; // Horizontal offset for loop start and end points.

            edgePath = `M ${sourceX - offset},${sourceY} A ${radiusX},${radiusY} 0 1,0 ${
                targetX + offset
            },${targetY}`;
        } else {
            /**
             * Uses getBezierPath from reactflow to generate the path for regular edges.
             */
            [edgePath] = getBezierPath({
                sourceX: sourceX,
                sourceY: sourceY,
                sourcePosition,
                targetX: targetX,
                targetY: targetY,
                targetPosition,
            });
        }

        /**
         * Determines if the current edge is selected.
         *
         * @type {boolean}
         */
        const isSelected = isSelectedEdge(edge.id);

        /**
         * Determines if the current edge is hovered.
         *
         * @type {boolean}
         */
        const isHovered = hoveredEdgeId === edge.id;

        /**
         * Checks if the edge has any visibility conditions, such as showIfKey or hideIfKey.
         * Used to style the edge differently.
         *
         * @type {boolean}
         */
        const hasKeyCondition = edge.data.showIfKey || edge.data.hideIfKey;

        /**
         * Styles applied to the edge path based on its state (selected, hovered, or default).
         *
         * @type {Object}
         */
        const edgeStyle = {
            stroke: isSelected
                ? selectedEdgeStyle.stroke
                : isHovered
                ? (data.edgeColor || '#999')
                : (data.edgeColor || '#999'),
            strokeWidth: isSelected
                ? selectedEdgeStyle.strokeWidth
                : isHovered
                ? 2
                : 1,
            fill: 'none',
            cursor: 'pointer',
            strokeDasharray: hasKeyCondition ? '5,5' : undefined,
        };

        return (
            <g key={edge.id}>
                <defs>
                    <marker
                        id={`arrow-${edge.id}`}
                        markerWidth="10"
                        markerHeight="7"
                        refX="10"
                        refY="3.5"
                        orient="auto"
                        markerUnits="userSpaceOnUse"
                    >
                        <path
                            d="M0,0 L0,7 L10,3.5 z"
                            fill={
                                isSelected
                                    ? selectedEdgeStyle.stroke
                                    : edgeStyle.stroke
                            }
                        />
                    </marker>
                </defs>
                <path
                    id={`path-${edge.id}`}
                    d={edgePath}
                    style={edgeStyle}
                    markerEnd={`url(#arrow-${edge.id})`}
                    onMouseEnter={() => handleMouseEnter(edge.id)}
                    onMouseLeave={handleMouseLeave}
                    onClick={(event) => handleEdgeClick(edge.id, event)}
                />
                <path
                    d={edgePath}
                    stroke="transparent"
                    strokeWidth={15}
                    fill="none"
                    onMouseEnter={() => handleMouseEnter(edge.id)}
                    onMouseLeave={handleMouseLeave}
                    onClick={(event) => handleEdgeClick(edge.id, event)}
                    style={{ cursor: 'pointer' }}
                />
                {/**
                 * Author: Leon Liang
                 * Description: generate animation along the selected edge(s) 
                 */}
                {isSelected && (
                    <polygon points="0,-4 12,0 0,4" fill="green">
                        <animateMotion
                            dur="1.5s"
                            repeatCount="indefinite"
                            rotate="auto"
                        >
                            <mpath href={`#path-${edge.id}`} />
                        </animateMotion>
                    </polygon>
                )}
            </g>
        );
    });


    /**
     * LABEL RENDERING LOGIC
     */

    // Label coordinates
    let labelX, labelY;
    
    // Calculate the X and Y coordinates of the labels for self-loop edges, relative to the node
    if (isLoopEdge) {
        labelX = (sourceX + targetX) / 2;
        labelY = sourceY - 1.55 * radiusY;
    } else {
        /**
         * Use getBezierPath for regular edges.
         * getBezierPath is responsible for the curving path we currently see.
         * I do not know the specific path algorithm for it. Thank you, dev who made the library.
         *  */

        [, labelX, labelY] = getBezierPath({
            sourceX,
            sourceY,
            sourcePosition,
            targetX,
            targetY,
            targetPosition,
        });
    }

    /**
     * Renders the labels for the edges.
     * If there's only one edge, renders a single label.
     * If multiple edges connect the same nodes, stacks labels vertically.
     * Coloring logic:
     * - If the edge is selected: fill with #006666
     * - Otherwise: fill with boxColor
     *
     * @returns {JSX.Element} The rendered labels as SVG elements.
     */
    const renderLabels = () => {
        const boxWidth = 86;
        const boxHeight = 20;
        const isSelected = isSelectedEdge(id);
        const yOffset = 3;
        const padding = 4; // Padding inside the rectangle

        /**
         * Truncates text with an ellipsis if it exceeds the maximum width.
         */
        const getEllipsizedText = (text, maxWidth, ctx) => {
            const ellipsis = "...";
            let truncatedText = text;
            let textWidth = ctx.measureText(truncatedText).width;
            while (textWidth > maxWidth && truncatedText.length > 0) {
                truncatedText = truncatedText.slice(0, -1);
                textWidth = ctx.measureText(truncatedText + ellipsis).width;
            }
            return truncatedText.length < text.length ? truncatedText + ellipsis : text;
        };

        // Create a canvas context for measuring text width
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        ctx.font = "12px Arial";

        if (allEdges.length === 1) {
            // Only one edge connects the two nodes
            const maxTextWidth = boxWidth - padding * 2;
            const truncatedLabel = getEllipsizedText(data.label, maxTextWidth, ctx);

            return (
                <g
                    className={styles.edgeLabelGroup}
                    onMouseEnter={handleLabelMouseEnter}
                    onMouseLeave={handleLabelMouseLeave}
                    onClick={(event) => handleEdgeClick(id, event)}
                    style={{ cursor: "pointer" }}
                >
                    <rect
                        x={labelX - boxWidth / 2}
                        y={labelY - boxHeight / 2}
                        width={boxWidth}
                        height={boxHeight}
                        fill={isSelected ? "#006666" : boxColor}
                        stroke="#000"
                        strokeWidth="1"
                        pointerEvents="all"
                    />
                    <text
                        x={labelX}
                        y={labelY + yOffset}
                        textAnchor="middle"
                        alignmentBaseline="middle"
                        className={`${styles.edgeLabel} ${
                            isSelected ? styles.edgeLabelSelected : ""
                        }`}
                        pointerEvents="none"
                    >
                        {truncatedLabel}
                    </text>
                </g>
            );
        } else {
            // Multiple edges: stack them vertically
            const spacing = 0;
            const totalHeight = boxHeight * allEdges.length + spacing * (allEdges.length - 1);
            const startYPosition = labelY - boxHeight / 2 - (totalHeight - boxHeight);
            
            const labels = allEdges
                .slice()
                .reverse()
                .map((edgeItem, index) => {
                    const yPosition = startYPosition + index * boxHeight;
                    const isEdgeSelected = isSelectedEdge(edgeItem.id);
                    const maxTextWidth = boxWidth - padding * 2;
                    const truncatedLabel = getEllipsizedText(edgeItem.data.label, maxTextWidth, ctx);

                    return (
                        <g
                            key={`label-${edgeItem.id}`}
                            onClick={(event) => handleEdgeClick(edgeItem.id, event)}
                            style={{ cursor: "pointer" }}
                        >
                            <rect
                                x={labelX - boxWidth / 2}
                                y={yPosition}
                                width={boxWidth}
                                height={boxHeight}
                                fill={(edgeItem.data.edgeColor ? edgeItem.data.edgeColor : '#ffffff')}
                                stroke="#000"
                                strokeWidth="1"
                                pointerEvents="all"
                            />
                            <text
                                x={labelX}
                                y={yPosition + boxHeight / 2 + yOffset}
                                textAnchor="middle"
                                alignmentBaseline="middle"
                                className={styles.edgeLabel}
                                pointerEvents="none"
                            >
                                {truncatedLabel}
                            </text>
                        </g>
                    );
                });

            return (
                <g
                    className={styles.edgeLabelGroup}
                    onMouseEnter={handleLabelMouseEnter}
                    onMouseLeave={handleLabelMouseLeave}
                >
                    {labels}
                </g>
            );
        }
    };

    

    return (
        <svg style={{ overflow: 'visible', position: 'absolute', left: 0, top: 0 }}>
            <g className={styles.edgesGroup}>
                {edgePaths}
            </g>
            <g className={styles.labelsGroup}>
                {renderLabels()}
            </g>
        </svg>
    );

};

export default ClassroomCanvasEdge;


