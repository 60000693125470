import React, { useContext, useRef } from 'react';
import styles from './styles/NavbarMenu.module.css';
import AuthContext from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

/* Import FontAwesome and specific icons */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faChalkboardTeacher, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';

const NavbarMenu = ({ setShowMenu, user }) => {
    const { getLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();
    const menuRef = useRef(null);

    const handleClickProfile = async () => {
        try {
            if (user) {
                navigate(`/user/${user.username}`, { state: { fromNavbar: true } });
                setShowMenu(false);
            }
        } catch (error) {
            console.error(`Error retrieving user information: ${error}`);
        }
    };

    const handleClickClassrooms = () => {
        navigate('/my-classrooms');
        setShowMenu(false);
    };

    const handleClickLogout = async () => {
        try {
            await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/users/logout`, { withCredentials: true });
            await getLoggedIn();
            navigate("/logging-out");
            setShowMenu(false);
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    return (
        <div
            ref={menuRef}
            className={styles.dropdown}
            onClick={(e) => e.stopPropagation()}
        >
            {/* Account */}
            <div className={styles.menuItem} onClick={handleClickProfile}>
                <div className={styles.iconContainer}>
                    {user?.profilePicture ? (
                        <img
                            src={user.profilePicture}
                            alt="Profile"
                            className={styles.profilePicture}
                        />
                    ) : (
                        <FontAwesomeIcon icon={faUser} className={styles.icon} />
                    )}
                </div>
                <div className={styles.textContainer}>
                    <div className={styles.label}>Account</div>
                </div>
            </div>

            {/* Classrooms */}
            <div className={styles.menuItem} onClick={handleClickClassrooms}>
                <div className={styles.iconContainer}>
                    <FontAwesomeIcon icon={faChalkboardTeacher} className={styles.icon} />
                </div>
                <div className={styles.textContainer}>
                    <div className={styles.label}>Classrooms</div>
                </div>
            </div>

            {/* Logout */}
            <div className={styles.menuItem} onClick={handleClickLogout}>
                <div className={styles.iconContainer}>
                    <FontAwesomeIcon icon={faRightFromBracket} className={styles.icon} />
                </div>
                <div className={styles.textContainer}>
                    <div className={styles.label}>Logout</div>
                </div>
            </div>
        </div>
    );
};

export default NavbarMenu;
