import React, { useContext, useRef, useState } from 'react';
import styles from './styles/NavbarNotifications.module.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AuthContext from '../../../context/AuthContext';
import { transformIframes } from '../../../utils/utils';

/* Import FontAwesome and specific icons */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUserPlus,        // invite
    faBullhorn,        // announcement
    faComment,         // feedback
    faCommentDots,     // feedbackMessage
    faCheckCircle,     // acceptedInvite
    faBell             // fallback
} from '@fortawesome/free-solid-svg-icons';

const getNotificationIcon = (type) => {
    switch (type) {
        case 'invite':
            return faUserPlus;
        case 'announcement':
            return faBullhorn;
        case 'feedback':
            return faComment;
        case 'feedbackMessage':
            return faCommentDots;
        case 'acceptedInvite':
            return faCheckCircle;
        default:
            return faBell;
    }
};

const NavbarNotifications = ({ notifications, setNotifications, setShowNotifications }) => {
    const navigate = useNavigate();
    const { userId, user } = useContext(AuthContext);
    const notificationsRef = useRef(null);

    const [visibleNotifications, setVisibleNotifications] = useState(5);

    const markAsSeen = async (notificationId) => {
        try {
            await axios.patch(
                `${process.env.REACT_APP_API_URL_LOCAL}/api/notifications/markNotificationAsSeen/${userId}/${notificationId}`
            );
        } catch (err) {
            console.error('Error marking notification as seen:', err);
        }
    };

    const handleNotificationClick = async (notification) => {
        if (!notification || !notification._id || notification.notificationType === 'invite') {
            return;
        }
        if (!notification.seen) {
            await markAsSeen(notification._id);
            setNotifications((prev) =>
                prev.map((notif) =>
                    notif._id === notification._id ? { ...notif, seen: true } : notif
                )
            );
        }
        switch (notification.notificationType) {
            case 'announcement':
                navigate(`/my-classrooms/${notification.relatedClassroomId}/a`);
                break;
            case 'feedback':
            case 'feedbackMessage':
                navigate(`/user/${user.username}`, { state: { activeTab: 'Feedback' } });
                break;
            case 'acceptedInvite':
                navigate(`/my-classrooms/${notification.relatedClassroomId}/p`);
                break;
            default:
                break;
        }
        setShowNotifications(false);
    };

    const acceptInvite = async (relatedClassroomId, role, notificationId) => {
        try {
            await axios.patch(
                `${process.env.REACT_APP_API_URL_LOCAL}/api/notifications/acceptInvite`,
                {
                    relatedClassroomId,
                    role,
                    notificationId
                }
            );
            setNotifications((prev) => prev.filter((notif) => notif._id !== notificationId));
            setShowNotifications(false);
        } catch (err) {
            console.error('An error occurred:', err);
        }
    };

    const ignoreInvite = async (notificationId) => {
        try {
            const response = await axios.patch(
                `${process.env.REACT_APP_API_URL_LOCAL}/api/notifications/ignoreInvite`,
                { notificationId }
            );
            if (response.status === 200) {
                setNotifications((prev) => prev.filter((notif) => notif._id !== notificationId));
                setShowNotifications(false);
            } else {
                console.error('Error ignoring invite:', response.data);
            }
        } catch (err) {
            console.error('An error occurred:', err);
        }
    };

    const loadMoreNotifications = () => {
        setVisibleNotifications((prev) => prev + 6);
    };

    const sortedNotifications = [...notifications].sort((a, b) => {
        if (a.seen === b.seen) {
            return new Date(b.time) - new Date(a.time);
        }
        return a.seen ? 1 : -1;
    });

    return (
        <div
            ref={notificationsRef}
            className={styles.dropdown}
            onClick={(e) => e.stopPropagation()}
        >
            <div className={styles.header}>
                <h2>Notifications</h2>
            </div>

            {sortedNotifications.length > 0 ? (
                <>
                    {sortedNotifications.slice(0, visibleNotifications).map((notification) => (
                        <div
                            key={notification._id}
                            className={`${styles.notificationItem} ${!notification.seen ? styles.unseen : ''
                                }`}
                            onClick={() => handleNotificationClick(notification)}
                        >
                            <div className={styles.iconContainer}>
                                <FontAwesomeIcon
                                    icon={getNotificationIcon(notification.notificationType)}
                                />
                            </div>
                            <div className={styles.textContainer}>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: transformIframes(notification.message)
                                    }}
                                />
                                {notification.notificationType === 'invite' && (
                                    <div className={styles.notificationActions}>
                                        <button
                                            className={styles.acceptButton}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                acceptInvite(
                                                    notification.relatedClassroomId,
                                                    notification.role,
                                                    notification._id
                                                );
                                            }}
                                        >
                                            Accept Invite
                                        </button>
                                        <button
                                            className={styles.ignoreButton}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                ignoreInvite(notification._id);
                                            }}
                                        >
                                            Ignore
                                        </button>
                                    </div>
                                )}
                            </div>
                            {!notification.seen && <span className={styles.blueDot} />}
                        </div>
                    ))}
                    {visibleNotifications < sortedNotifications.length && (
                        <div className={styles.loadMoreContainer}>
                            <button
                                className={styles.loadMoreButton}
                                onClick={loadMoreNotifications}
                            >
                                See More
                            </button>
                        </div>
                    )}
                </>
            ) : (
                <div className={styles.noNotifications}>
                    You have no notifications
                </div>
            )}
        </div>
    );
};

export default NavbarNotifications;
