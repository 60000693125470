import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "./navbar/Navbar";
import styles from './MainLayout.module.css';
import AuthContext from "../../context/AuthContext";

const MainLayout = ({ children }) => {
    const location = useLocation();
    const { loggedIn } = useContext(AuthContext);

    // Check if the pathname starts with '/create'
    const displayNavbar = !["/login", "/register", "/authenticate", "/forgot-password", "/reset-password"].includes(location.pathname) &&
                            !location.pathname.startsWith("/create");

    return (
        <div className={styles.mainContainer}>
            {displayNavbar && <Navbar />}
            <div
                className={`${styles.bodyContainer} ${!displayNavbar ? styles.noNavbar : ''}`}
            >
                {children}
            </div>
        </div>
    );
};

export default MainLayout;
