import React, { useContext, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './styles/ClassroomSidebar.module.css';
import { ClassroomContext } from '../../context/ClassroomContext';
import { classroomImages } from '../../utils/utils';
import {
    FaTachometerAlt,
    FaUsers,
    FaCog,
    FaBullhorn,
    FaScroll,
    FaFolderOpen,
    FaInfoCircle,
    FaChevronLeft
} from 'react-icons/fa';

const ClassroomSidebar = () => {
    const { classroom } = useContext(ClassroomContext);
    const location = useLocation();

    // We only want to pick a random fallback image once.
    const randomImageRef = useRef(null);
    if (!randomImageRef.current) {
        const randomIndex = Math.floor(Math.random() * classroomImages.length);
        randomImageRef.current = classroomImages[randomIndex];
    }
    const fallbackImage = randomImageRef.current;

    // Helper to determine the "main" and "sub" routes correctly
    const getActiveRoutes = (pathname) => {
        // /my-classrooms/:classroomId/:main/:sub?
        const segments = pathname.split('/');
        let main = segments[3];
        let sub = segments[4];

        // If the user is on one of the dashboard subpages (/a, /l, /f) directly
        // then treat main as 'd' (Dashboard) and sub as that segment.
        if (['a', 'l', 'f'].includes(main)) {
            sub = main;
            main = 'd';
        }
        return { main, sub };
    };

    const { main, sub } = getActiveRoutes(location.pathname);

    // Hard-coded nav structure
    const mainLinks = [
        {
            label: 'Dashboard',
            path: 'd',
            icon: <FaTachometerAlt className={styles.icon} />,
            sub: [
                { label: 'Announcements', path: 'a', icon: <FaBullhorn style={{ marginRight: '6px' }} /> },
                { label: 'Logs', path: 'l', icon: <FaScroll style={{ marginRight: '6px' }} /> },
                { label: 'Folders', path: 'f', icon: <FaFolderOpen style={{ marginRight: '6px' }} /> }
            ],
            alwaysOpen: true
        },
        {
            label: 'About',
            path: 'i',
            icon: <FaInfoCircle className={styles.icon} />
        },
        {
            label: 'People',
            path: 'p',
            icon: <FaUsers className={styles.icon} />
        },
        {
            label: 'Settings',
            path: 'settings',
            icon: <FaCog className={styles.icon} />
        }
    ];

    useEffect(() => {
        if (classroom) {
            console.log("classroom: ", classroom);
        }
    }, [classroom]);

    return (
        <div className={styles.sidebarContainer}>
            {/* Back icon at top-left */}
            <Link to="/my-classrooms" className={styles.backIcon}>
                <FaChevronLeft />
            </Link>

            {/* Top Section: Classroom name, owner, and image */}
            {classroom && (
                <>
                    <div className={styles.classroomName}>{classroom.name}</div>
                    {classroom.owner && (
                        <div className={styles.classroomOwner}>
                            Owner: {classroom.owner.username}
                        </div>
                    )}
                    <img
                        src={(classroom.image && classroom.image.trim()) ? classroom.image : fallbackImage}
                        alt={classroom.name}
                        className={styles.classroomImage}
                    />
                </>
            )}

            {/* Navigation List */}
            <ul className={styles.navList}>
                {mainLinks.map(mainItem => {
                    // Are any sub-items active?
                    const anySubActive = mainItem.sub?.some(s => s.path === sub);
                    // A main link is active if it matches the route and no sub-route is active
                    const mainActive = main === mainItem.path && !anySubActive;

                    return (
                        <li key={mainItem.path}>
                            <Link
                                to={`/my-classrooms/${classroom?._id}/${mainItem.path}`}
                                className={`${styles.navItem} ${mainActive ? styles.active : ''}`}
                            >
                                {mainItem.icon}
                                {mainItem.label}
                            </Link>

                            {mainItem.alwaysOpen && mainItem.sub && (
                                <ul className={styles.subNavList}>
                                    {mainItem.sub.map(subItem => {
                                        const subActive = sub === subItem.path;
                                        return (
                                            <li key={subItem.path}>
                                                <Link
                                                    to={`/my-classrooms/${classroom?._id}/${subItem.path}`}
                                                    className={`${styles.subNavItem} ${subActive ? styles.active : ''}`}
                                                >
                                                    {subItem.icon}
                                                    {subItem.label}
                                                </Link>
                                            </li>
                                        );
                                    })}
                                </ul>
                            )}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default ClassroomSidebar;
