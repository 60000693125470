import React, { useState, useEffect, useContext } from 'react';
import styles from './styles/FolderModal.module.css';
import { ClassroomContext } from '../../context/ClassroomContext';
import profileIcon from '../../images/profileicon.png';

const FolderModal = ({
    isOpen,
    onClose,
    mode,            // 'create' or 'edit'
    folder,          // the "child" folder if editing
    parentFolder,    // the "parent" folder (currently opened in Folder.js)
    onFolderCreated,
    onFolderUpdated,
    onFolderDeleted,
}) => {
    const { classroom, createFolder, updateFolder, deleteFolder } = useContext(ClassroomContext);

    const [folderName, setFolderName] = useState('');
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [isPrivate, setIsPrivate] = useState(false);
    const [error, setError] = useState(null);
    const [closing, setClosing] = useState(false); // fade-out animation

    // Is there a parent folder in the UI context?
    // If so, we can only use that parent's allowedStudents. If none, it's a top-level folder in the classroom.
    const parentIsPresent = !!parentFolder;
    const parentIsPrivate = parentFolder?.isPrivate || false;
    const parentAllowedStudents = parentFolder?.allowedStudents || [];

    // All possible students for this child folder:
    // 1) If there's a parent folder, limit to parent's allowedStudents
    // 2) Otherwise, for top-level, use the entire classroom
    const allPossibleStudents = parentIsPresent
        ? parentAllowedStudents
        : classroom?.students || [];

    useEffect(() => {
        if (!isOpen && !closing) return;
    
        if (mode === 'edit' && folder) {
            // We're editing an existing subfolder (child) or possibly a top-level folder
            setFolderName(folder.name || '');
            setIsPrivate(folder.isPrivate || false);
    
            // Filter out null/undefined or incomplete entries
            let currentAllowed = (folder.allowedStudents || []).filter(st => st && st._id);
    
            if (parentIsPresent) {
                // Parent is private => ensure child doesn't exceed parent's allowedStudents
                const parentAllowedIds = parentAllowedStudents
                    .filter(st => st && st._id) // filter out invalid entries in parent's list, too
                    .map(st => st._id.toString());
    
                currentAllowed = currentAllowed.filter(st =>
                    parentAllowedIds.includes(st._id.toString())
                );
            }
    
            // Convert to array of IDs
            setSelectedStudents(currentAllowed.map(st => st._id));
        } else {
            // Create mode: brand-new folder
            setFolderName('');
    
            // If there's a parent folder and it is private => subfolder is forcibly private
            if (parentIsPresent && parentIsPrivate) {
                setIsPrivate(true);
    
                // Pre-select all parent's allowedStudents (also filtering out any null/incomplete)
                const allIds = parentAllowedStudents
                    .filter(st => st && st._id)
                    .map(st => st._id);
                setSelectedStudents(allIds);
            } else {
                // Otherwise, default to not private
                setIsPrivate(false);
                setSelectedStudents([]);
            }
        }
    }, [isOpen, folder, parentFolder, mode]);
        

    const handleNameChange = (e) => setFolderName(e.target.value);

    const toggleStudentSelection = (studentId) => {
        if (selectedStudents.includes(studentId)) {
            setSelectedStudents(selectedStudents.filter((id) => id !== studentId));
        } else {
            setSelectedStudents([...selectedStudents, studentId]);
        }
    };

    const handleIsPrivateChange = (e) => {
        const newVal = e.target.checked;
        setIsPrivate(newVal);

        // If toggling private ON, select everyone from allPossibleStudents
        if (newVal) {
            const ids = allPossibleStudents.map(s => s._id);
            setSelectedStudents(ids);
        } else {
            // If toggling OFF, empty the list
            setSelectedStudents([]);
        }
    };

    const handleSubmit = async () => {
        if (!folderName.trim()) {
            setError('Folder name cannot be empty');
            return;
        }
        setError(null);

        // If there's a parent folder, we must reference its _id. Otherwise, top-level.
        const parentFolderId = parentIsPresent ? parentFolder._id : null;

        const folderData = {
            name: folderName,
            classroomId: classroom._id,
            allowedStudents: isPrivate ? selectedStudents : [],
            isPrivate,
            parentFolderId,
        };

        try {
            if (mode === 'create') {
                const newFolder = await createFolder(folderData);
                if (onFolderCreated) {
                    onFolderCreated(newFolder);
                }
            } else if (mode === 'edit') {
                const updatedFolder = await updateFolder(folder._id, folderData);
                if (onFolderUpdated) {
                    onFolderUpdated(updatedFolder);
                }
            }
            triggerClose();
        } catch (error) {
            console.error('Error in FolderModal submit:', error);
            setError('An error occurred. Please try again.');
        }
    };

    const handleDelete = async () => {
        if (!folder) return;
        try {
            await deleteFolder(folder._id);
            if (onFolderDeleted) {
                onFolderDeleted(folder._id);
            }
            triggerClose();
        } catch (error) {
            console.error('Error deleting folder:', error);
            setError('An error occurred. Please try again.');
        }
    };

    const triggerClose = () => {
        setClosing(true);
        setTimeout(() => {
            onClose();
            setClosing(false);
        }, 300); // match your CSS fade-out duration
    };

    if (!isOpen && !closing) return null;

    return (
        <div className={`${styles.modalOverlay} ${closing ? styles.fadeOut : styles.fadeIn}`}>
            <div className={styles.modalContent}>
                {/* Header */}
                <div className={styles.modalHeader}>
                    <h2 className={styles.modalTitle}>
                        {mode === 'create' ? 'Create Folder' : 'Edit Folder'}
                    </h2>
                </div>

                {/* Body */}
                <div className={styles.modalBody}>
                    <label htmlFor="folderName" className={styles.label}>
                        Folder Name
                    </label>
                    <input
                        type="text"
                        id="folderName"
                        value={folderName}
                        onChange={handleNameChange}
                        className={styles.modalInput}
                    />

                    <div className={styles.checkboxContainer}>
                        <input
                            type="checkbox"
                            id="isPrivate"
                            checked={isPrivate}
                            onChange={handleIsPrivateChange}
                            disabled={parentIsPrivate} 
                            // If the parent folder is private, the child is forcibly private
                        />
                        <label htmlFor="isPrivate">Private Folder</label>
                    </div>

                    {isPrivate && (
                        <>
                            <div className={styles.listHeader}>
                                Select students to have access:
                            </div>
                            <ul className={styles.list}>
                                {allPossibleStudents.map((student) => {
                                    const selected = selectedStudents.includes(student._id);
                                    return (
                                        <li
                                            key={student._id}
                                            onClick={() => toggleStudentSelection(student._id)}
                                            className={`${styles.item} ${selected ? styles.selectedItem : ''}`}
                                        >
                                            <img
                                                src={profileIcon}
                                                alt="User Icon"
                                                className={styles.profileIcon}
                                            />
                                            {student.username}
                                        </li>
                                    );
                                })}
                            </ul>
                        </>
                    )}

                    {error && <div className={styles.error}>{error}</div>}
                </div>

                {/* Footer */}
                <div className={styles.modalFooter}>
                    <div className={styles.buttonContainer}>
                        <button
                            className={`${styles.buttonBase} ${styles.saveButton}`}
                            onClick={handleSubmit}
                        >
                            {mode === 'create' ? 'Create' : 'Save'}
                        </button>
                        {mode === 'edit' && folder && (
                            <button
                                onClick={handleDelete}
                                className={`${styles.buttonBase} ${styles.deleteButton}`}
                            >
                                Delete
                            </button>
                        )}
                        <button
                            className={`${styles.buttonBase} ${styles.cancelButton}`}
                            onClick={triggerClose}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FolderModal;
