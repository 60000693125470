import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Heatmap from './HeatmapCanvas';
import styles from './styles/HeatmapViewer.module.css';
import HeatmapOptions from './HeatmapOptions';
import ViewNode from './ViewHeatmapNode';
import ViewEdge from './ViewHeatmapEdge';
import CanvasContext from '../../context/CanvasContext';

const HeatmapViewer = () => {
    const { scenarioId } = useParams();
    const { selectedNodeId, selectedEdgeId, getHeatmapInfo } = useContext(CanvasContext);

    useEffect(() => {
        if (scenarioId) {
            getHeatmapInfo();
            console.log()
        }
    }, [scenarioId])

    return (
        <div className={styles.container}>
            {/* Node Creation Sidebar (left side) */}
            <div className={styles.createNodeSidebar}>
                <HeatmapOptions />
            </div>

            {/* Canvas Section (middle) */}
            <div className={styles.canvas}>
                <Heatmap />
            </div>

            {/* Node/Edge/Log Editor Sidebar (right side) */}
            <div className={styles.editSidebar}>
                {selectedNodeId && <ViewNode />}
                {selectedEdgeId && !selectedNodeId && <ViewEdge />}
            </div>
        </div>
    );
};

export default HeatmapViewer;
