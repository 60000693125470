import DOMPurify from 'dompurify';


export function transformIframes(markdown) {
    // 1) Return an empty string if input not valid
    if (typeof markdown !== 'string') {
        return '';
    }

    // 2) Convert Markdown → HTML
    const rawHtml = markdown

    // 3) Sanitize the HTML to remove any potentially harmful code
    const sanitizedHtml = DOMPurify.sanitize(rawHtml, {
        ADD_TAGS: ['iframe', 'oembed'],
        ADD_ATTR: ['url', 'allow', 'allowfullscreen', 'frameborder', 'scrolling'],
    });

    // 4) Create a DOM parser to parse the sanitized HTML
    const parser = new DOMParser();
    const doc = parser.parseFromString(sanitizedHtml, 'text/html');

    // 5) Process <a> tags linking to YouTube
    const anchors = doc.querySelectorAll('a[href*="youtube.com/watch"], a[href*="youtu.be/"]');
    anchors.forEach(anchor => {
        const url = anchor.getAttribute('href');
        const iframe = createYouTubeIframe(url);
        if (iframe) {
            anchor.parentNode.replaceChild(iframe, anchor);
        }
    });

    // 6) Process <oembed> tags
    const oembeds = doc.querySelectorAll('oembed[url*="youtube.com/watch"], oembed[url*="youtu.be/"]');
    oembeds.forEach(oembed => {
        const url = oembed.getAttribute('url');
        const iframe = createYouTubeIframe(url);
        if (iframe) {
            oembed.parentNode.replaceChild(iframe, oembed);
        }
    });

    // 7) Process <iframe> tags with a "url" attribute
    const iframes = doc.querySelectorAll('iframe[url*="youtube.com/watch"], iframe[url*="youtu.be/"]');
    iframes.forEach(iframe => {
        const url = iframe.getAttribute('url');
        const newIframe = createYouTubeIframe(url);
        if (newIframe) {
            iframe.parentNode.replaceChild(newIframe, iframe);
        }
    });

    // 8) Serialize the document back to HTML
    return doc.body.innerHTML;
}

/**
 * Creates an embedded YouTube iframe from a watch or share URL, supporting:
 *   - optional start/end timestamps (in seconds or m/s format).
 *   - optional autoplay via ?autoplay=1 or ?autoplay=true in the URL.
 *
 * Example valid URLs:
 *   1) https://www.youtube.com/watch?v=N2XF0fBS57c
 *   2) https://www.youtube.com/watch?v=N2XF0fBS57c&t=1m40s&end=1m45s
 *   3) https://youtu.be/N2XF0fBS57c?start=100&end=105&autoplay=true
 *
 * @param {string} url - The YouTube watch/share URL (may include start/end/autoplay params).
 * @returns {HTMLElement|null} The iframe element or null if parsing fails.
 */
function createYouTubeIframe(url) {
    try {
        const urlObj = new URL(url);
        const videoId = getYouTubeVideoId(urlObj);
        if (!videoId) return null;

        // Parse out query params
        const params = new URLSearchParams(urlObj.search);

        // 1) Check both 't' and 'start' for the initial time
        let start = params.get('t') || params.get('start');
        let end = params.get('end');

        // 2) Convert time strings like '1m40s', '100', '1:40' to seconds
        start = parseTimeToSeconds(start);
        end = parseTimeToSeconds(end);

        // 3) Determine if we have autoplay in the URL
        const autoplayVal = params.get('autoplay');

        // 4) Construct the final embed URL
        let embedUrl = `https://www.youtube.com/embed/${videoId}`;
        const embedParams = new URLSearchParams();

        // Add start/end
        if (start) embedParams.set('start', start);
        if (end) embedParams.set('end', end);

        // Add autoplay if `autoplay=1` or `autoplay=true` was present
        if (autoplayVal === '1' || autoplayVal === 'true') {
            embedParams.set('autoplay', '1');
        }

        // If any query params exist, append them
        if ([...embedParams].length > 0) {
            embedUrl += `?${embedParams.toString()}`;
        }

        // 5) Construct the iframe element
        const iframe = document.createElement('iframe');
        iframe.setAttribute('width', '560');
        iframe.setAttribute('height', '315');
        iframe.setAttribute('src', embedUrl);
        iframe.setAttribute('frameborder', '0');
        iframe.setAttribute('allowfullscreen', '');
        // Important: must include "autoplay" in "allow" or browsers may block it
        iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');

        return iframe;
    } catch (error) {
        console.error('Error creating YouTube iframe:', error);
        return null;
    }
}

/**
 * Extracts a YouTube video ID from a given URL object (e.g., watch URL or short youtu.be link).
 * @param {URL} urlObj - The URL object for the YouTube link.
 * @returns {string|null} The video ID or null if it cannot be extracted.
 */
function getYouTubeVideoId(urlObj) {
    // Example "watch?v=VIDEO_ID" or "youtu.be/VIDEO_ID"
    if (urlObj.hostname.includes('youtu.be')) {
        // Path starts with /VIDEO_ID
        return urlObj.pathname.substring(1);
    }
    if (
        urlObj.hostname.includes('youtube.com') &&
        urlObj.searchParams.get('v')
    ) {
        return urlObj.searchParams.get('v');
    }
    return null;
}

/**
 * Converts a string like '1m40s', '100', or '1:40' into total seconds (number).
 * @param {string|null} timeStr - The raw string (e.g. '1m40s', '2m', '45s', '100').
 * @returns {number|undefined} The total seconds or undefined if invalid/missing.
 */
function parseTimeToSeconds(timeStr) {
    if (!timeStr) return undefined;

    // If purely numeric, assume it's already seconds (e.g. '100').
    if (/^\d+$/.test(timeStr)) {
        return parseInt(timeStr, 10);
    }

    // Check for '1:40' => 1 minute + 40 seconds
    if (timeStr.includes(':')) {
        const [minutes, seconds] = timeStr.split(':').map(Number);
        if (!isNaN(minutes) && !isNaN(seconds)) {
            return minutes * 60 + seconds;
        }
    }

    // Otherwise, handle '1m40s', '2m', '45s'
    let totalSeconds = 0;
    const minuteMatch = timeStr.match(/(\d+)m/);
    const secondMatch = timeStr.match(/(\d+)s/);

    if (minuteMatch) {
        totalSeconds += parseInt(minuteMatch[1], 10) * 60;
    }
    if (secondMatch) {
        totalSeconds += parseInt(secondMatch[1], 10);
    }

    return totalSeconds > 0 ? totalSeconds : undefined;
}




export function getMediaSrc(audio) {
    if (typeof audio === 'string') {
        // If audio is already a string (URL or data URL), return it directly
        return audio;
    } else if (audio && typeof audio === 'object' && audio.src) {
        // If audio is an object with a 'src' property, return the 'src'
        return audio.src;
    } else {
        // Handle any other cases (e.g., null or undefined)
        return '';
    }
}

export function stripHtml(html) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
}

export function calculateTimeSince(date) {
    const now = Date.now();
    const created = new Date(date).getTime();
    const diffInSeconds = Math.floor((now - created) / 1000);
    let unit = 'second';
    let diffInUnit = diffInSeconds;
    const units = {
        year: 31536000,
        month: 2628000,
        day: 86400,
        hour: 3600,
        minute: 60,
    };
    for (const [unitKey, secondsInUnit] of Object.entries(units)) {
        if (diffInSeconds >= secondsInUnit) {
            unit = unitKey;
            diffInUnit = Math.floor(diffInSeconds / secondsInUnit);
            break;
        }
    }
    if (diffInUnit !== 1) {
        unit += 's';
    }
    return `${diffInUnit} ${unit}`;
}

/**
 * Validates the structure and connectivity of a directed graph represented by nodes and edges.
 *
 * The function performs comprehensive checks to ensure that the graph meets the following criteria:
 *
 * **Start and End Nodes:**
 * - The graph must contain at least one start node (node with `data.endpointType === 'start'`) and one end node (node with `data.endpointType === 'end'`).
 * - If no start node is found, the function returns an error.
 * - If multiple start nodes are found, an error is reported for each extra start node.
 * - If no end node is found, the function returns an error.
 *
 * **Connectivity and Path Validation:**
 * - All nodes should be reachable from the start node(s).
 * - From each start node, there must be at least one path leading to an end node.
 * - Nodes that are not reachable from any start node are reported as unreachable.
 * - Dead ends (nodes with no outgoing edges that are not end nodes) are reported.
 * - Dead cycles (cycles that do not lead to an end node) are detected and reported.
 *
 * **Edge Validity:**
 * - Self-loops (edges where the source and target are the same node) must have `data.choiceType === 'hint'`.
 *   - If a self-loop edge is found with a different `choiceType`, an error is reported.
 *
 * **Algorithm Overview:**
 * - The function uses depth-first search (DFS) to traverse the graph starting from the start nodes.
 * - It maintains a `visited` set to keep track of visited nodes and a `recursionStack` to detect cycles.
 * - A `pathSuccess` map records whether a node leads to an end node.
 * - Cycles are detected when a node is encountered that is already in the `recursionStack`.
 *   - If a cycle does not lead to an end node, it's considered a dead cycle.
 * - The function collects errors throughout the traversal and returns them at the end.
 *
 * @param {Array<Object>} nodes - An array of node objects representing the nodes in the graph.
 *   Each node object should have the following properties:
 *   - **id** `{string}`: A unique identifier for the node.
 *   - **data** `{Object}`: An object containing additional data for the node.
 *     - **endpointType** `{string}` *(optional)*: The type of the node endpoint.
 *       - Possible values:
 *         - `'start'`: Indicates a start node.
 *         - `'end'`: Indicates an end node.
 *         - Other values or undefined: Regular node.
 *
 * @param {Array<Object>} edges - An array of edge objects representing the edges in the graph.
 *   Each edge object should have the following properties:
 *   - **source** `{string}`: The id of the source node.
 *   - **target** `{string}`: The id of the target node.
 *   - **data** `{Object}`: An object containing additional data for the edge.
 *     - **choiceType** `{string}` *(optional)*: The type of the edge.
 *       - Possible values:
 *         - `'hint'`: Indicates a hint edge (allowed to be a self-loop).
 *         - Other values or undefined: Regular edge.
 *
 * @returns {Array<Object>} - An array of error objects indicating the validation errors found in the graph.
 *   Each error object may have the following properties:
 *   - **node** `{Object}` *(optional)*: The node object where the error was detected (if applicable).
 *   - **edge** `{Object}` *(optional)*: The edge object where the error was detected (if applicable).
 *   - **reason** `{string}`: A string describing the reason for the error.
 *     - Possible error reasons include:
 *       - `'No start node found'`: The graph does not contain any start node.
 *       - `'Multiple start nodes'`: The graph contains more than one start node.
 *       - `'No end node found'`: The graph does not contain any end node.
 *       - `'Dead end'`: A node (not an end node) has no outgoing edges.
 *       - `'Unreachable node'`: A node is not reachable from any start node.
 *       - `'Part of a dead cycle'`: A node is part of a cycle that does not lead to an end node.
 *       - `'Needs to be a hint'`: An edge is a self-loop but is not marked as a 'hint'.
 *
 * @example
 * // Example node:
 * const node = {
 *   id: 'node1',
 *   data: {
 *     endpointType: 'start'
 *   }
 * };
 *
 * // Example edge:
 * const edge = {
 *   source: 'node1',
 *   target: 'node2',
 *   data: {
 *     choiceType: 'normal'
 *   }
 * };
 *
 * // Usage:
 * const errors = checkValidity(nodesArray, edgesArray);
 * if (errors.length > 0) {
 *   errors.forEach(error => {
 *     console.error(`Error: ${error.reason}`, error.node || error.edge);
 *   });
 * } else {
 *   console.log('Graph is valid.');
 * }
 */
export function checkValidity(nodes, edges) {
    let startNodes = nodes.filter(node => node.data.endpointType === 'start');
    let endNodes = nodes.filter(node => node.data.endpointType === 'end');

    console.log("START NODES: ", startNodes);

    const errors = [];
    const visited = new Set();
    const recursionStack = new Set();
    const pathSuccess = new Map();

    if (startNodes.length === 0) {
        errors.push({ reason: 'No start node found' });
        return errors;
    }
    if (startNodes.length > 1) {
        startNodes.forEach(node => errors.push({ node, reason: 'Multiple start nodes' }));
    }
    if (endNodes.length === 0) {
        errors.push({ reason: 'No end node found' });
        return errors;
    }

    function hasPathToEnd(nodeId) {
        let stack = [nodeId];
        let visited = new Set();

        while (stack.length > 0) {
            let currentNodeId = stack.pop();
            let currentNode = nodes.find(n => n.id === currentNodeId);

            visited.add(currentNodeId);

            if (currentNode.data.endpointType === 'end') {
                return true;
            }

            let outgoingEdges = edges.filter(edge => edge.source === currentNodeId && !visited.has(edge.target));
            outgoingEdges.forEach(edge => {
                stack.push(edge.target);
            });
        }
        return false;
    }

    function dfs(node, path = []) {
        let currentPathSuccess = pathSuccess.get(node.id) || false;

        if (visited.has(node.id)) {
            return currentPathSuccess;
        }

        visited.add(node.id);
        recursionStack.add(node.id);
        path.push(node);

        let success = node.data.endpointType === 'end';

        const outgoingEdges = edges.filter(edge => edge.source === node.id);
        if (!success && outgoingEdges.length == 0) {
            errors.push({ node, reason: 'Dead end' });
        }

        outgoingEdges.forEach(edge => {
            const targetNode = nodes.find(n => n.id === edge.target);
            if (edge.source === edge.target && edge.data.choiceType !== 'hint') {
                errors.push({ edge, reason: 'Needs to be a hint' });
            } else if (targetNode) {
                if (recursionStack.has(targetNode.id)) {
                    let cycle = path.slice(path.indexOf(targetNode)).map(n => n.id);
                    let validCycle = hasPathToEnd(targetNode.id);
                    if (!validCycle) {
                        cycle.forEach(nodeId => pathSuccess.set(nodeId, false));
                    }
                    success = validCycle || success;
                } else {
                    success = dfs(targetNode, path) || success;
                }
            }
        });

        recursionStack.delete(node.id);
        path.pop();
        pathSuccess.set(node.id, success);

        if (success) {
            path.forEach(p => pathSuccess.set(p.id, true));
        }

        return success;
    }

    startNodes.forEach(node => dfs(node));

    nodes.forEach(node => {
        if (!visited.has(node.id) && node.data.endpointType !== 'start') {
            errors.push({ node, reason: 'Unreachable node' });
        }
        if (!pathSuccess.get(node.id) && visited.has(node.id)) {
            errors.push({ node, reason: 'Part of a dead cycle' });
        }
    });

    return errors;
}



export const classroomImages = [
    'https://images.unsplash.com/photo-1509062522246-3755977927d7?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8Y2xhc3Nyb29tfGVufDB8fDB8fHwy',
    'https://images.unsplash.com/photo-1580582932707-520aed937b7b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Y2xhc3Nyb29tfGVufDB8fDB8fHwy',
    'https://images.unsplash.com/file-1695862032969-7a88a86ce4d5image?dpr=2&w=416&auto=format&fit=crop&q=60',
    'https://images.unsplash.com/photo-1604134967494-8a9ed3adea0d?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8Y2xhc3Nyb29tfGVufDB8fDB8fHwy',
    'https://images.unsplash.com/photo-1503676260728-1c00da094a0b?q=80&w=2922&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://images.unsplash.com/photo-1544640808-32ca72ac7f37?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8Y2xhc3Nyb29tfGVufDB8fDB8fHwy',
    'https://images.unsplash.com/photo-1585637071663-799845ad5212?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8Y2xhc3Nyb29tfGVufDB8fDB8fHwy',
    'https://images.unsplash.com/photo-1588072432836-e10032774350?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8Y2xhc3Nyb29tfGVufDB8fDB8fHwy',
    'https://images.unsplash.com/photo-1588075592446-265fd1e6e76f?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8Y2xhc3Nyb29tfGVufDB8fDB8fHwy',
    'https://images.unsplash.com/photo-1581726707445-75cbe4efc586?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGNsYXNzcm9vbXxlbnwwfHwwfHx8Mg%3D%3D',
    'https://images.unsplash.com/photo-1617721926586-4eecce739745?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGNsYXNzcm9vbXxlbnwwfHwwfHx8Mg%3D%3D'
];
