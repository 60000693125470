import React, { useEffect, useContext } from 'react';
import { useLocation, Routes, Route } from 'react-router-dom';
import styles from './styles/MyClassrooms.module.css';
import MyClassroomsSidebar from './MyClassroomsSidebar';
import MyClassroomsHeader from './MyClassroomsHeader';
import MyClassroomsGrid from './MyClassroomsGrid';
import Classroom from './Classroom';
import ClassroomSidebar from './ClassroomSidebar';
import Loading from '../loading/Loading'; // Importing the Loading component
import { ClassroomContext } from '../../context/ClassroomContext';

const MyClassrooms = () => {
    const location = useLocation();
    const { loading } = useContext(ClassroomContext);

    // Check if there's anything after "/my-classrooms/"
    const isClassroomPage = location.pathname.startsWith('/my-classrooms/') &&
        location.pathname !== '/my-classrooms/';

    useEffect(() => {
        console.log("Current path: ", location.pathname);
        console.log("Is classroom page: ", isClassroomPage);
    }, [location.pathname, isClassroomPage]);

    return (
        <div className={styles.myClassroomsContainer}>
            <MyClassroomsHeader />
            <div className={styles.myClassroomsBody}>
            {loading ? (
                <div className={styles.loading}>
                    <Loading />
                </div>
            ) : (
                <>
                    {isClassroomPage ? <ClassroomSidebar /> : <MyClassroomsSidebar />}
                    <Routes>
                        <Route path="/" element={<MyClassroomsGrid />} />
                        <Route path="/:classroomId/*" element={<Classroom />} />
                    </Routes>
                </>
            )}

            </div>
        </div>
    );
};

export default MyClassrooms;
