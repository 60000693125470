import React, { useEffect, useState } from 'react';
import styles from './styles/About.module.css';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const imageStyle = {
  width: 'calc(50% - 10px)',
  height: '45vh',
  objectFit: 'cover',
  imageRendering: 'auto',
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease-in-out',
};



const About = () => {
  const [isHovered, setIsHovered] = useState(false);

  const hoverStyle = {
    ...imageStyle,
    transform: isHovered ? 'scale(1.05)' : 'none',
  };
  const [activeIndex, setActiveIndex] = useState(null);
  const [contactForm, setContactForm] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [isValid, setIsValid] = useState(true);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setContactForm({ ...contactForm, [name]: value });
    if (name === 'email') {
      const emailRegex = /\S+@\S+\.\S+/;
      setIsValid(emailRegex.test(value));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const [email, setEmail] = useState('');

  useEffect(() => {
    gsap.from(".milestone", { duration: 1, y: -50, opacity: 0, scrollTrigger: ".milestone" });
  }, []);
  return (
    <div className={styles.aboutContainer}>
      <section className={styles.intro}>
        <h1>Welcome to Scenario</h1>
      </section>
      <section className={styles.whatWeDo}>
        <h2>What We Do</h2>
        <p></p>
      </section>
      <section className={styles.features}>
        <h2>Core Features</h2>
        
      </section>

      <section className={styles.vision}>
      </section>
      <section className={styles.chartSection}>
      </section>
      <h2>Meet the Founders</h2>
      <section className={styles.founders} style={{ marginTop: '20px' }}>
        <div className={`${styles.founder} ${styles.founderSection}`}>
          <div class="founder">
            <div class="founder-image-container">
              <img src={require('../../images/andrew.jpg')} alt="Andrew Guo" />
            </div>
          </div>
          <div>
            <h3>Andrew Guo</h3>
          

          </div>
        </div>
        <div className={`${styles.founder} ${styles.founderSection}`}>
          <img src={require('../../images/dean.jpg')} alt="Dean Gladish" />
          <div>
            <h3>Dean Gladish</h3>
          

          </div>
        </div>
      </section>


      
    </div >
  )
};



export default About;
