import React, { useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import AuthContext from "./context/AuthContext";
import FrontPage from "./components/mainlayout/body/FrontPage";
import HomePage from "./components/mainlayout/body/HomePage";
import ScenarioTitlePage from "./components/scenarios/create/ScenarioTitlePage";
import OtherScenarios from "./components/mainlayout/body/OtherScenarios";
import MainLayout from "./components/mainlayout/MainLayout";
import Search from "./components/search/Search";
import LoggingOut from "./components/auth/LoggingOut";
import VerifyEmail from "./components/auth/VerifyEmail";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import Browse from "./components/search/Browse";
import About from "./components/about/About";
import MyClassrooms from "./components/classrooms/MyClassrooms";
import TableOfContents from "./components/scenarios/create/TableOfContents";
import CreateScenario from "./components/scenarios/create/CreateScenario";
import ViewScenario from "./components/scenarios/view/ViewScenario";
import Account from "./components/profile/Account";
import AuthForm from "./components/auth/AuthForm";
import AcceptInvite from "./components/classrooms/AcceptInvite";
import { ClassroomContextProvider } from "./context/ClassroomContext"; // import ClassroomContextProvider
import { ReactFlowProvider } from "reactflow";
import { CanvasProvider } from "./context/CanvasContext";
import HeatmapViewer from "./components/heatmap/HeatmapViewer";
import ClassroomCanvasViewer from "./components/classrooms/classroomcanvas/ClassroomCanvasViewer";



function Router() {
    const { loggedIn } = useContext(AuthContext);

    return (
        <BrowserRouter>
            <MainLayout>
                <Routes>
                    {
                        loggedIn === false && (
                            <>
                                <Route path="/authenticate" element={<AuthForm />} />
                                <Route exact path="/" element={<FrontPage />} />
                                <Route path="/verify-email" element={<VerifyEmail />} />
                                <Route path="/forgot-password" element={<ForgotPassword />} />
                                <Route path="/reset-password" element={<ResetPassword />} />
                                <Route path="/logging-out" element={!loggedIn ? <Navigate to="/" /> : <LoggingOut />} />
                                <Route path="/home" element={!loggedIn ? <Navigate to="/" /> : <HomePage />} />
                                <Route path="/view/:scenarioId" element={<ViewScenario />} />
                                <Route path="/user/:username" element={<Account />} />
                                <Route path="/search" element={<Search />} />
                                <Route path="/browse/:tag" element={<Browse />} />
                                <Route path="/about" element={<About />} />
                                <Route path="/create/:scenarioId/canvas" element={<CreateScenario />} />
                                <Route path="/my-classrooms/accept-invite" element={<AcceptInvite />} />
                            </>
                        )
                    }
                    {
                        loggedIn === true && (
                            <>
                                <Route path="/authenticate" element={<Navigate to="/home" />} />
                                <Route path="/" element={loggedIn ? <Navigate to="/home" /> : <FrontPage />} />
                                <Route path="/logging-out" element={loggedIn ? <Navigate to="/" /> : <LoggingOut />} />
                                <Route path="/verify-email" element={<VerifyEmail />} />
                                <Route exact path="/home" element={<HomePage />} />
                                <Route path="/browse" element={<OtherScenarios />} />
                                <Route path="/create/:scenarioId" element={<ScenarioTitlePage />} />
                                <Route path="/create/:scenarioId/table-of-contents" element={<TableOfContents />} />
                                <Route path="/create/:scenarioId/preview" element={<ViewScenario />} />
                                <Route path="/view/:scenarioId" element={<ViewScenario />} />
                                <Route path="/user/:username" element={<Account />} />
                                <Route path="/search" element={<Search />} />
                                {/* <Route path="/checkout" element={<CheckoutPage />} /> */}
                                <Route path="/browse/:tag" element={<Browse />} />
                                <Route path="/about" element={<About />} />
                                
                                {/* Wrap the Classroom-related routes with ClassroomContextProvider */}
                                <Route path="/my-classrooms/*" element={
                                    <ClassroomContextProvider userId={loggedIn}>
                                        <MyClassrooms />
                                    </ClassroomContextProvider>
                                } />

                                <Route
                                    path="/logs/heatmap/:scenarioId"
                                    element={
                                        <ReactFlowProvider>
                                            <CanvasProvider>
                                                <HeatmapViewer />
                                            </CanvasProvider>
                                        </ReactFlowProvider>
                                    }
                                />

                                <Route
                                    path="/my-classrooms/:classroomId/l/:scenarioId"
                                    element={
                                        <ClassroomContextProvider userId={loggedIn}>
                                            <ReactFlowProvider>
                                                <CanvasProvider>
                                                    <ClassroomCanvasViewer />
                                                </CanvasProvider>
                                            </ReactFlowProvider>
                                        </ClassroomContextProvider>
                                    }
                                />



                                <Route path="/create/:scenarioId/canvas" element={<CreateScenario />} />
                                <Route path="/my-classrooms/accept-invite" element={<AcceptInvite />} />
                            </>
                        )
                    }
                </Routes>
            </MainLayout>
        </BrowserRouter>
    );
};

export default Router;
