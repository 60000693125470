import React from 'react';
import styles from './styles/Loading.module.css';

const Loading = () => {
    return (
        <div className={styles.loaderContainer}>
            <div className={styles.spinner}></div>
            <p className={styles.loadingText}>Loading...</p>
        </div>
    );
};

export default Loading;
