import React, { useContext, useEffect, useState } from "react";
import styles from './styles/ClassroomAbout.module.css';
import { ClassroomContext } from '../../context/ClassroomContext';
import { transformIframes } from "../../utils/utils";
import TextEditor from "../texteditor/TextEditor";

const ClassroomAbout = () => {
    const { classroom, isOwner, updateClassroom } = useContext(ClassroomContext);

    const [aboutText, setAboutText] = useState(classroom.about || '');
    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    // On initial load, set the local `aboutText` state from the classroom context
    useEffect(() => {
        if (classroom?.about) {
            setAboutText(classroom.about);
        }
    }, [classroom?.about]);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = async () => {
        setIsSaving(true);
        try {
            await updateClassroom(classroom?._id, { about: aboutText });
            setIsEditing(false);
        } catch (error) {
            console.error(error);
        } finally {
            setIsSaving(false);
        }
    };

    const handleCancelClick = () => {
        setAboutText(classroom.about || '');
        setIsEditing(false);
    };

    // Separate handler for the main text editor
    const handleTextEditorChange = (data) => {
        setAboutText(data);
    };

    return (
        <div className={styles.classroomAbout}>
            {isEditing ? (
                <div>
                    <TextEditor
                        initialData={aboutText}
                        onChange={handleTextEditorChange}
                    />
                    <div className={styles.buttonGroup}>
                        <button
                            onClick={handleSaveClick}
                            className={styles.saveButton}
                            disabled={isSaving}
                        >
                            {isSaving ? 'Saving...' : 'Save'}
                        </button>
                        <button
                            onClick={handleCancelClick}
                            className={styles.cancelButton}
                            disabled={isSaving}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            ) : (
                <div>
                    <div
                        className={styles.aboutContent}
                        dangerouslySetInnerHTML={{ __html: transformIframes(aboutText) }}
                    />
                    {isOwner && (
                        <button onClick={handleEditClick} className={styles.editButton}>
                            Edit
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default ClassroomAbout;
