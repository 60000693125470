// ClassroomContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import AuthContext from './AuthContext';
import { useNavigate } from 'react-router-dom';

export const ClassroomContext = createContext();

export const ClassroomContextProvider = ({ children }) => {
    const { userId } = useContext(AuthContext);
    const [classrooms, setClassrooms] = useState([]);
    const [joinedClassrooms, setJoinedClassrooms] = useState([]);
    const [classroom, setClassroom] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isTeacher, setIsTeacher] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (userId) {
            fetchClassrooms(userId);
        }
    }, [userId]);

    const fetchClassrooms = async (userId) => {
        setLoading(true);
        try {
            const [classroomsResponse, joinedResponse] = await Promise.all([
                axios.get(
                    `${process.env.REACT_APP_API_URL_LOCAL}/api/classrooms/user/${userId}`
                ),
                axios.get(
                    `${process.env.REACT_APP_API_URL_LOCAL}/api/users/${userId}/joined`
                ),
            ]);

            setClassrooms(classroomsResponse.data);
            setJoinedClassrooms(joinedResponse.data.joinedClassrooms);
        } catch (error) {
            console.error('Error fetching classrooms:', error);
        } finally {
            setLoading(false);
        }
    };

    const createClassroom = async (classroomData) => {
        try {
            const formData = new FormData();
            formData.append('name', classroomData.name);
            if (classroomData.image) {
                formData.append('image', classroomData.image);
            }

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL_LOCAL}/api/classrooms`,
                formData
            );

            const newClassroom = response.data;

            // Update the classrooms state
            setClassrooms((prevClassrooms) => [...prevClassrooms, newClassroom]);

            return newClassroom;
        } catch (error) {
            console.error('Failed to create the classroom:', error);
            throw error; // Rethrow error to handle it in the component
        }
    };

    const fetchClassroom = async (classroomId) => {
        try {
            const classroomResponse = await axios.get(
                `${process.env.REACT_APP_API_URL_LOCAL}/api/classrooms/classroom/${classroomId}`
            );
            const classroomData = classroomResponse.data;

            setClassroom(classroomData);

            const isTeacher = classroomData.teachers.some(
                (teacher) => teacher._id === userId
            );
            setIsTeacher(isTeacher);

            const isOwner = classroomData.owner._id === userId;
            setIsOwner(isOwner);
        } catch (error) {
            console.error('Error fetching classroom:', error);
        }
    };

    const updateClassroom = async (id, updates) => {
        try {

            console.log("UPDATES: ", updates);
            const classroomUpdate = await axios.patch(
                `${process.env.REACT_APP_API_URL_LOCAL}/api/classrooms/${id}`,
                updates
            );

            const updatedClassrooms = classrooms.map((classroom) =>
                classroom._id === id ? { ...classroom, ...updates } : classroom
            );

            const updatedJoinedClassrooms = joinedClassrooms.map((classroom) =>
                classroom._id === id ? { ...classroom, ...updates } : classroom
            );

            setClassrooms(updatedClassrooms);
            setJoinedClassrooms(updatedJoinedClassrooms);
            setClassroom(classroomUpdate.data);
        } catch (error) {
            console.error('Error updating classroom:', error);
        }
    };

    const deleteClassroom = async (classroomId) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_URL_LOCAL}/api/classrooms/${classroomId}`);
            if (response.status === 200) {
                setClassroom(null);
                // Remove classroom from state arrays
                setClassrooms(prevClassrooms => prevClassrooms.filter(classroom => classroom._id !== classroomId));
                setJoinedClassrooms(prevJoinedClassrooms => prevJoinedClassrooms.filter(classroom => classroom._id !== classroomId));

                // Redirect to classrooms overview
                navigate("/my-classrooms");
            }
        } catch (error) {
            console.error("Failed to delete classroom:", error);
        }
    };

    const createFolder = async (folderData) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL_LOCAL}/api/folders`,
                folderData
            );
            const newFolder = response.data;

            // Update classroom state if it's a top-level folder
            if (!folderData.parentFolderId) {
                setClassroom((prevClassroom) => ({
                    ...prevClassroom,
                    folders: [...prevClassroom.folders, newFolder],
                }));
            }

            return newFolder;
        } catch (error) {
            console.error('Error creating folder:', error);
            throw error;
        }
    };

    const updateFolder = async (folderId, updates) => {
        try {
            // Fetch existing folder to check if it's top-level
            const folderResponse = await axios.get(
                `${process.env.REACT_APP_API_URL_LOCAL}/api/folders/folder/${folderId}`
            );
            const existingFolder = folderResponse.data;

            const response = await axios.patch(
                `${process.env.REACT_APP_API_URL_LOCAL}/api/folders/${folderId}`,
                updates
            );
            const updatedFolder = response.data;

            // Update classroom state if it's a top-level folder
            if (!existingFolder.parentFolder) {
                setClassroom((prevClassroom) => ({
                    ...prevClassroom,
                    folders: prevClassroom.folders.map((folder) =>
                        folder._id === folderId ? updatedFolder : folder
                    ),
                }));
            }

            return updatedFolder;
        } catch (error) {
            console.error('Error updating folder:', error);
            throw error;
        }
    };

    const deleteFolder = async (folderId) => {
        try {
            // Fetch existing folder to check if it's top-level
            const folderResponse = await axios.get(
                `${process.env.REACT_APP_API_URL_LOCAL}/api/folders/folder/${folderId}`
            );
            const existingFolder = folderResponse.data;

            await axios.delete(
                `${process.env.REACT_APP_API_URL_LOCAL}/api/folders/${folderId}`
            );

            // Update classroom state if it's a top-level folder
            if (!existingFolder.parentFolder) {
                setClassroom((prevClassroom) => ({
                    ...prevClassroom,
                    folders: prevClassroom.folders.filter(
                        (folder) => folder._id !== folderId
                    ),
                }));
            }
        } catch (error) {
            console.error('Error deleting folder:', error);
            throw error;
        }
    };

    return (
        <ClassroomContext.Provider
            value={{
                classrooms,
                joinedClassrooms,
                classroom,
                fetchClassrooms,
                createClassroom,
                fetchClassroom,
                updateClassroom,
                deleteClassroom,
                isOwner,
                setIsOwner,
                setClassrooms,
                isTeacher,
                setIsTeacher,
                loading,
                createFolder,
                updateFolder,
                deleteFolder,
            }}
        >
            {children}
        </ClassroomContext.Provider>
    );
};
