import React, { useContext } from 'react';
import styles from './styles/ClassroomCanvasOptions.module.css';
import CanvasContext from '../../../context/CanvasContext';
import { useNavigate } from 'react-router-dom';

const ClassroomCanvasOptions = () => {
    const {
        classroomStudentsWithLogs,
        highlightStudentLog // We'll define this in our context
    } = useContext(CanvasContext);

    const navigate = useNavigate();

    if (!classroomStudentsWithLogs?.length) {
        return <p>No student logs available.</p>;
    }

    return (
        <div className={styles.optionsContainer}>
            <h2>Classroom Logs</h2>
            {classroomStudentsWithLogs.map((student) => {
                return (
                    <div className={styles.studentSection} key={student._id}>
                        <h3 style={{ color: student.color }}>
                            {student.username}
                        </h3>
                        {/* Potentially show a profile pic */}
                        {/* {student.profilePicture && (
                            <img
                                src={student.profilePicture}
                                alt={`${student.username}'s avatar`}
                                className={styles.profilePic}
                            />
                        )} */}
                        {student.logs.length === 0 ? (
                            <p>No logs for this scenario.</p>
                        ) : (
                            <ul>
                                {student.logs.map((log) => {
                                    const created = new Date(log.createdAt)
                                        .toLocaleString();
                                    return (
                                        <li
                                            key={log.logId}
                                            onClick={() => highlightStudentLog(student._id, log)}
                                            className={styles.clickableLog}
                                        >
                                            Log created on {created}
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default ClassroomCanvasOptions;
