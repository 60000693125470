import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './styles/MyClassroomsGrid.module.css';
import useIsMobile from '../../utils/useIsMobile';
import { ClassroomContext } from '../../context/ClassroomContext';
import EditClassroomModal from './EditClassroomModal';
import MyClassroomsGridItem from './MyClassroomsGridItem'; // Import the Grid Item component
import { classroomImages } from '../../utils/utils';
import Loading from '../loading/Loading';

const MyClassroomsGrid = () => {
    const { classrooms, joinedClassrooms, setClassrooms } = useContext(ClassroomContext);
    const isMobile = useIsMobile();
    const [openedDropdown, setOpenedDropdown] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editingClassroom, setEditingClassroom] = useState(null);
    const [searchTerm, setSearchTerm] = useState(''); // State for search input

    // Filtered classrooms based on search term
    const filteredClassrooms = classrooms.filter((classroom) =>
        classroom.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const filteredJoinedClassrooms = joinedClassrooms.filter((classroom) =>
        classroom.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (openedDropdown && !event.target.closest(`.${styles.optionsDropdown}`) && !event.target.closest(`.${styles.optionsButton}`)) {
                setOpenedDropdown(false);
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [openedDropdown]);

    const openEditModal = (classroom) => {
        setEditingClassroom(classroom);
        setIsEditModalOpen(true);
    };


    return (
        <div className={styles.container}>
            {isMobile && (
                <ul className={styles.classroomList}>
                    {classrooms.map((classroom) => (
                        <li key={classroom._id}>
                            <Link to={`/my-classrooms/${classroom._id}/a`}>
                                {classroom.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            )}

            <EditClassroomModal
                isOpen={isEditModalOpen}
                onClose={() => setIsEditModalOpen(false)}
                classroom={editingClassroom}
                setClassrooms={setClassrooms}
            />

            {/* Search Bar */}
            <input
                type="text"
                className={styles.searchBar}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search for a classroom..."
            />


            <div className={styles.classroomGrid}>
                {filteredClassrooms.slice().reverse().map((classroom, index) => (
                    <MyClassroomsGridItem
                        key={classroom._id}
                        classroom={classroom}
                        classroomImages={classroomImages}
                        index={index}
                        openedDropdown={openedDropdown}
                        setOpenedDropdown={setOpenedDropdown}
                        openEditModal={openEditModal}
                    />
                ))}
                {filteredJoinedClassrooms.slice().reverse().map((classroom, index) => (
                    <MyClassroomsGridItem
                        key={classroom._id}
                        classroom={classroom}
                        classroomImages={classroomImages}
                        index={index}
                        openedDropdown={openedDropdown}
                        setOpenedDropdown={setOpenedDropdown}
                        openEditModal={openEditModal}
                    />
                ))}
            </div>
        </div>
    );
};

export default MyClassroomsGrid;
