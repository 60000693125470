import React, { useState } from 'react';
import axios from 'axios';
import TextEditor from '../texteditor/TextEditor';
import styles from './styles/AccountProfile.module.css';
import ProfileIcon from '../../images/profileicon.png';
import { transformIframes } from '../../utils/utils';

const AccountProfile = ({ userIsAccountOwner, accountOwner, setAccountOwner }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [bio, setBio] = useState(accountOwner.bio);
    const [profilePicture, setProfilePicture] = useState(accountOwner.profilePicture);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleClickEditProfile = () => {
        setIsEditing(true);
    };

    const handleClickSaveChanges = async () => {
        try {
            const formData = new FormData();
            formData.append('bio', bio);
            if (selectedFile) {
                formData.append('image', selectedFile);
            }
            const response = await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/users/${accountOwner._id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setAccountOwner((prevOwner) => ({
                ...prevOwner,
                bio,
                profilePicture: response.data.profilePicture || prevOwner.profilePicture,
            }));
            setIsEditing(false);
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    const handleClickCancel = () => {
        setIsEditing(false);
        setBio(accountOwner.bio);
        setProfilePicture(accountOwner.profilePicture);
    };

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setProfilePicture(URL.createObjectURL(file));
        }
    };

    const handleImageClick = () => {
        document.getElementById('fileInput').click();
    };

    // Separate handler for the main text editor
    const handleTextEditorChange = (data) => {
        setBio(data);
    };

    return (
        <div className={styles.profile}>
            {!isEditing ? (
                <>
                <div className={styles.profilePicture}>
                    <img src={profilePicture ? profilePicture : ProfileIcon} alt="Profile" />
                </div>
                    {userIsAccountOwner && (
                        <button className={styles.editButton} onClick={handleClickEditProfile}>
                            Edit Profile
                        </button>
                    )}
                    <h3>{accountOwner.username}</h3>
                    <p dangerouslySetInnerHTML={{ __html: transformIframes(accountOwner.bio) }}></p>
                </>
            ) : (
                <>
                    <div className={styles.profileHeader}>
                        <h3>Edit Profile</h3>
                    </div>
                    <div className={styles.profilePicture}>
                        <img
                            src={profilePicture ? profilePicture : ProfileIcon}
                            alt="Profile"
                            className={styles.profilePicture}
                            onClick={handleImageClick}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>

                    <input id="fileInput" type="file" style={{ display: 'none' }} onChange={handleFileSelect} />
                    <h5>{accountOwner.username}</h5>
                    <div className={styles.editorContainer}>
                        <TextEditor
                            initialData={bio}
                            onChange={handleTextEditorChange}
                        />
                    </div>
                    <div className={styles.buttonContainer}>
                        <button className={styles.cancelButton} onClick={handleClickCancel}>
                            Cancel
                        </button>
                        <button className={styles.saveButton} onClick={handleClickSaveChanges}>
                            Save Changes
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default AccountProfile;
