import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styles from './styles/ClassroomViewer.module.css';
import ClassroomCanvasOptions from './ClassroomCanvasOptions';
// import ViewNode from './ViewHeatmapNode';
// import ViewEdge from './ViewHeatmapEdge';
import CanvasContext from '../../../context/CanvasContext';
import ClassroomCanvas from './ClassroomCanvas';

const ClassroomCanvasViewer = () => {
    const { classroomId, scenarioId } = useParams();
    const { selectedNodeId, selectedEdgeId, fetchClassroomScenarioLogs } = useContext(CanvasContext);

    useEffect(() => {
        if (classroomId && scenarioId) {
            fetchClassroomScenarioLogs();
        }
    }, [classroomId, scenarioId])

    return (
        <div className={styles.container}>
            {/* Node Creation Sidebar (left side) */}
            <div className={styles.createNodeSidebar}>
                <ClassroomCanvasOptions />
            </div>

            {/* Canvas Section (middle) */}
            <div className={styles.canvas}>
                <ClassroomCanvas />
            </div>

            {/* Node/Edge/Log Editor Sidebar (right side) */}
            {/* <div className={styles.editSidebar}>
                {selectedNodeId && <ViewNode />}
                {selectedEdgeId && !selectedNodeId && <ViewEdge />}
            </div> */}
        </div>
    );
};

export default ClassroomCanvasViewer;
