import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './styles/Folders.module.css';
import settingsIcon from '../../images/settingsicon.png';
import AuthContext from '../../context/AuthContext';
import { ClassroomContext } from '../../context/ClassroomContext';
import FolderModal from './FolderModal';
import ScenarioModal from './ScenarioModal';
import { FaFolder, FaFolderPlus, FaFileAlt, FaArrowLeft } from 'react-icons/fa';
import Loading from '../loading/Loading';
import blankImage from '../../images/canvas.png';

const Folder = () => {
    const { classroomId, folderId } = useParams();
    const { userId } = useContext(AuthContext);
    const navigate = useNavigate();
    const { isTeacher, updateClassroom } = useContext(ClassroomContext);

    const [folder, setFolder] = useState(null);
    const [subfolders, setSubfolders] = useState([]);
    const [scenarios, setScenarios] = useState([]);
    const [parentFolder, setParentFolder] = useState(null);

    // Modal state
    const [isScenarioModalOpen, setIsScenarioModalOpen] = useState(false);
    const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState('create');
    const [currentFolder, setCurrentFolder] = useState(null);
    const [selectedScenarios, setSelectedScenarios] = useState([]);

    // Loading state
    const [loading, setLoading] = useState(false);

    // Fetch folder data
    useEffect(() => {
        const fetchFolder = async (id) => {
            setLoading(true);
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL_LOCAL}/api/folders/folder/${id}`
                );
                const folderData = response.data;
                setFolder(folderData);
                setParentFolder(folderData.parentFolder);
                setSubfolders(folderData.folders || []);
                setScenarios(folderData.scenarios || []);
            } catch (error) {
                console.error('Error fetching folder:', error);
            } finally {
                setLoading(false);
            }
        };

        if (folderId) {
            fetchFolder(folderId);
        }
    }, [folderId]);

    const handleFolderClick = (subfolder) => {
        // Teachers can always access the folder
        if (isTeacher) {
            navigate(`/my-classrooms/${classroomId}/f/${subfolder._id}`);
            return;
        }
        // Check access based on privacy settings
        if (subfolder.isPrivate && !subfolder.allowedStudents.includes(userId)) {
            alert('You do not have permission to access this folder.');
        } else {
            navigate(`/my-classrooms/${classroomId}/f/${subfolder._id}`);
        }
    };

    const backToParentFolder = () => {
        if (parentFolder) {
            navigate(`/my-classrooms/${classroomId}/f/${parentFolder._id}`);
        } else {
            // If no parent folder, go back to top-level of this classroom
            navigate(`/my-classrooms/${classroomId}/f`);
        }
    };

    const openCreateModal = () => {
        setModalMode('create');
        setCurrentFolder(null);
        setIsFolderModalOpen(true);
    };

    const openEditModal = (subfolder) => {
        setModalMode('edit');
        setCurrentFolder(subfolder);
        setIsFolderModalOpen(true);
    };

    const closeModal = () => {
        setIsFolderModalOpen(false);
        setCurrentFolder(null);
    };

    const handleAddScenarios = () => {
        setSelectedScenarios(folder?.scenarios || []);
        setIsScenarioModalOpen(true);
    };

    const handleAddSubfolder = (newFolder) => {
        setSubfolders((prev) => [...prev, newFolder]);
    };

    const handleUpdateSubfolder = (updatedFolder) => {
        setSubfolders((prev) =>
            prev.map((f) => (f._id === updatedFolder._id ? updatedFolder : f))
        );
    };

    const handleDeleteSubfolder = (deletedFolderId) => {
        setSubfolders((prev) =>
            prev.filter((f) => f._id !== deletedFolderId)
        );
    };

    const handleScenarioSelection = (scenario) => {
        if (selectedScenarios.some((s) => s._id === scenario._id)) {
            setSelectedScenarios((prev) => prev.filter((s) => s._id !== scenario._id));
        } else {
            setSelectedScenarios((prev) => [...prev, scenario]);
        }
    };

    const handleSubmitScenarios = async () => {
        const selectedScenarioIds = selectedScenarios.map((scenario) => scenario._id);
        try {
            const response = await axios.patch(
                `${process.env.REACT_APP_API_URL_LOCAL}/api/folders/${folderId}`,
                { scenarios: selectedScenarioIds }
            );
            const updatedFolder = response.data;
            setFolder(updatedFolder);
            setScenarios(updatedFolder.scenarios);
            updateClassroom(classroomId, { scenarios: selectedScenarioIds });
            setIsScenarioModalOpen(false);
        } catch (error) {
            console.error('Error updating scenarios:', error);
        }
    };

    // If folder data is still loading, show <Loading/>
    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <div className={styles.foldersContainer}>
                <div className={styles.foldersBody}>
                    <div className={styles.folderItem}>
                        <FaArrowLeft
                            className={styles.folderItemImage}
                            onClick={backToParentFolder}
                        />
                        <div>
                            {parentFolder && parentFolder.name
                                ? `Back to ${parentFolder.name}`
                                : 'Back to My Folders'}
                        </div>
                    </div>

                    {isTeacher && (
                        <>
                            <div className={styles.folderItem}>
                                <FaFolderPlus
                                    className={styles.folderItemImage}
                                    onClick={openCreateModal}
                                />
                                <div>Create Folder</div>
                            </div>
                            <div className={styles.folderItem}>
                                <FaFileAlt
                                    className={styles.folderItemImage}
                                    onClick={handleAddScenarios}
                                />
                                <div>Add/Remove Scenarios</div>
                            </div>
                        </>
                    )}

                    {subfolders.map((subfolder) => (
                        <div
                            key={subfolder._id}
                            className={styles.folderItem}
                        >
                            <div
                                onClick={() => handleFolderClick(subfolder)}
                                style={{
                                    textDecoration: 'none',
                                    color: 'inherit',
                                    cursor: 'pointer'
                                }}
                            >
                                <FaFolder className={styles.folderItemImage} />
                                <div className={styles.folderName}>{subfolder?.name}</div>
                            </div>
                            {isTeacher && (
                                <img
                                    src={settingsIcon}
                                    className={styles.optionsButton}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openEditModal(subfolder);
                                    }}
                                    alt="Edit Folder"
                                />
                            )}
                        </div>
                    ))}

                    {scenarios.map((scenario) => (
                        <div
                            key={scenario._id}
                            className={styles.folderItem}
                            onClick={() =>
                                navigate(`/view/${scenario._id}`, {
                                    state: { hasAccess: true },
                                })
                            }
                        >
                            {/* If scenario.image is not set, use blankImage */}
                            <img
                                src={scenario?.image || blankImage}
                                className={styles.folderItemImage}
                                alt="Scenario"
                            />
                            <div className={styles.folderName}>
                                {scenario?.title}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <FolderModal
                isOpen={isFolderModalOpen}
                onClose={closeModal}
                mode={modalMode}
                folder={currentFolder}  // Child folder we’re editing
                parentFolder={folder}   // The current “parent” folder
                onFolderCreated={handleAddSubfolder}
                onFolderUpdated={handleUpdateSubfolder}
                onFolderDeleted={handleDeleteSubfolder}
            />

            <ScenarioModal
                isOpen={isScenarioModalOpen}
                onClose={() => setIsScenarioModalOpen(false)}
                selectedScenarios={selectedScenarios}
                onScenarioSelect={handleScenarioSelection}
                onSubmit={handleSubmitScenarios}
            />
        </>
    );
};

export default Folder;
