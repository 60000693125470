import React, { useState } from 'react';
import axios from 'axios';
import { transformIframes } from '../../../utils/utils';
import styles from './styles/ChoicesBody.module.css';
import HintModal from './HintModal';
import { useScenario } from '../../../context/ScenarioContext';

const ChoicesBody = ({ choices, setCurrentSituationId, isScenarioLogged, preview, scenario, userKeys, setUserKeys, userId }) => {
    const [showHintModal, setShowHintModal] = useState(false);
    const [hintText, setHintText] = useState('');
    const [isProcessing, setIsProcessing] = useState(false); // <- NEW
    const { addDecisionToLog } = useScenario();

    const handleClickChoice = async (choice) => {
        // Guard to prevent spamming
        if (isProcessing) return;

        setIsProcessing(true); // start processing
        try {
            // 1. Grant any keys
            if (choice.grantsKeys && choice.grantsKeys.length > 0) {
                const newKeys = choice.grantsKeys.filter(key => !userKeys.includes(key));
                if (newKeys.length > 0) {
                    if (userId) {
                        await axios.patch(
                            `${process.env.REACT_APP_API_URL_LOCAL}/api/users/${userId}/grantKeys`,
                            { keys: newKeys }
                        );
                    }
                    setUserKeys(prevKeys => [...prevKeys, ...newKeys]);
                }
            }

            // 2. Log the decision
            if (isScenarioLogged && !preview) {
                await addDecisionToLog({ 
                    type: 'choice', 
                    id: choice._id, 
                    title: choice.title 
                });
            }

            // 3. Navigation / Hint logic
            if (!choice.hasHint) {
                setCurrentSituationId(choice.nextSituation._id);
            } else {
                if (choice.situation === choice.nextSituation._id) {
                    setHintText(choice.hint);
                    setShowHintModal(true);
                } else {
                    setCurrentSituationId(choice.nextSituation._id);
                }
            }
        } finally {
            setIsProcessing(false); // done
        }
    };

    const closeModal = () => {
        setShowHintModal(false);
        setHintText('');
    };

    const isChoiceVisible = (choice) => {
        if (!choice.key) {
            return true;
        }
        const hasKey = userKeys.includes(choice.key);
        if (choice.showIfKey) {
            return hasKey;
        } else if (choice.hideIfKey) {
            return !hasKey;
        } else {
            return true;
        }
    };

    const filteredChoices = choices.filter(isChoiceVisible);

    return (
        <>
            <div className={styles.container}>
                {filteredChoices.map((choice) => (
                    <div
                        key={choice._id}
                        className={`
                            ${styles.choice} 
                            ${isProcessing ? styles.disabledChoice : ''}
                        `}
                        onClick={() => !isProcessing && handleClickChoice(choice)}
                        dangerouslySetInnerHTML={{ __html: transformIframes(choice.text) }}
                    />
                ))}
            </div>

            {showHintModal && (
                <HintModal hintText={hintText} closeModal={closeModal} />
            )}
        </>
    );
};

export default ChoicesBody;
