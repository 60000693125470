import React, { useContext, useEffect } from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import { ClassroomContext } from '../../context/ClassroomContext';
import ClassroomTabs from './ClassroomTabs';
import ClassroomAnnouncements from './ClassroomAnnouncements';
import Folders from './Folders';
import Folder from './Folder';
import People from './People';
import ClassroomSettings from './ClassroomSettings';
import ClassroomAbout from './ClassroomAbout';
import ClassroomLogs from './ClassroomLogs';
import styles from './styles/Classroom.module.css';
import AuthContext from '../../context/AuthContext';
import ClassroomDashboard from './ClassroomDashboard';
import Loading from '../loading/Loading';

const Classroom = () => {
    const { 
        classroom, 
        isTeacher, 
        isOwner, 
        fetchClassroom,
    } = useContext(ClassroomContext);

    const { classroomId } = useParams();
    const { userId } = useContext(AuthContext);

    useEffect(() => {
        if (classroomId && userId) {
            fetchClassroom(classroomId, userId);
        }
    }, [classroomId, userId]); // Add loading as a dependency

    return (
        <div className={styles.classroomContainer}>
            {classroom ? (
                <>
                    <Routes>
                        <Route path="/a" element={
                            <ClassroomAnnouncements />} 
                        />
                        <Route path="/d" element={<ClassroomDashboard />} />
                        <Route path="/f" element={<Folders/>} />
                        <Route path="/f/:folderId" element={<Folder />} />
                        <Route path="/p" element={<People />} />
                        <Route path="/i" element={<ClassroomAbout />} />
                        <Route path="/l" element={<ClassroomLogs />} />
                        <Route path="/settings" element={<ClassroomSettings />} />
                    </Routes>
                </>
            ) : (
                <Loading/>
            )}
        </div>
    );
};

export default Classroom;
