/**
 * @author Elek Thomas-Toth
 */

import React, { useContext, useState, useEffect } from 'react';
import CanvasContext from '../../context/CanvasContext';
import styles from './styles/ViewHeatmapEdge.module.css';

const ViewEdge = () => {
    const { selectedEdgeId, getEdge, updateEdge, deleteEdge, heatmapEdgeUsers } = useContext(CanvasContext);

    const [edge, setEdge] = useState(null);
    const [label, setLabel] = useState('');
    const [choiceType, setChoiceType] = useState('');
    const [students, setStudents] = useState();

    useEffect(() => {
        const fetchEdge = async () => {
            if (selectedEdgeId) {
                const fetchedEdge = getEdge(selectedEdgeId);
                setEdge(fetchedEdge);
                setLabel(fetchedEdge.data.label || '');
                setChoiceType(fetchedEdge.data.choiceType || '');

                heatmapEdgeUsers.forEach(edge => {
                    if (edge[0] === selectedEdgeId) {
                        setStudents(edge[1]);
                    }
                });
            }
        };

        fetchEdge();
    }, [selectedEdgeId, getEdge]);


    if (!edge) {
        return <div>Loading...</div>;
    }

    return (
        <div className={styles.editEdge}>
            <h3>Edit Edge</h3>
            <div className={styles.field}>
                <label>Label: { selectedEdgeId }</label>
            </div>

            <div>
                <h2>
                    Students: {students?.length}
                </h2>
                {students?.map((student, index) => (
                        <li key={index}>{ student } </li>
                    )
                )}
            </div>

        </div>
    );
};

export default ViewEdge;
