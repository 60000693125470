// Folders.js
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles/Folders.module.css';
import settingsIcon from '../../images/settingsicon.png';
import { ClassroomContext } from '../../context/ClassroomContext';
import AuthContext from '../../context/AuthContext';
import FolderModal from './FolderModal';
import { FaFolder, FaFolderPlus } from 'react-icons/fa';

const Folders = () => {
    const { classroom, isTeacher } = useContext(ClassroomContext);
    const { userId } = useContext(AuthContext);
    const navigate = useNavigate();

    const [folders, setFolders] = useState(classroom?.folders || []);
    const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState('create');
    const [currentFolder, setCurrentFolder] = useState(null);

    useEffect(() => {
        if (classroom) {
            setFolders(classroom.folders);
        }
    }, [classroom]);

    const handleFolderClick = (folder) => {
        // Teachers can always access the folder
        if (isTeacher) {
            navigate(`/my-classrooms/${classroom._id}/f/${folder._id}`);
            return;
        }
    
        // Check access based on privacy settings
        if (folder.isPrivate && !folder.allowedStudents.includes(userId)) {
            alert('You do not have permission to access this folder.');
        } else {
            navigate(`/my-classrooms/${classroom._id}/f/${folder._id}`);
        }
    };
    

    const openCreateModal = () => {
        setModalMode('create');
        setCurrentFolder(null);
        setIsFolderModalOpen(true);
    };

    const openEditModal = (folder) => {
        setModalMode('edit');
        setCurrentFolder(folder);
        setIsFolderModalOpen(true);
    };

    const closeModal = () => {
        setIsFolderModalOpen(false);
        setCurrentFolder(null);
    };

    const handleAddFolder = (newFolder) => {
        setFolders((prevFolders) => [...prevFolders, newFolder]);
    };

    const handleUpdateFolder = (updatedFolder) => {
        setFolders((prevFolders) =>
            prevFolders.map((f) => (f._id === updatedFolder._id ? updatedFolder : f))
        );
    };

    const handleDeleteFolder = (deletedFolderId) => {
        setFolders((prevFolders) => prevFolders.filter((f) => f._id !== deletedFolderId));
    };

    return (
        <div className={styles.foldersContainer}>
            
            <FolderModal
                isOpen={isFolderModalOpen}
                onClose={closeModal}
                mode={modalMode}
                folder={currentFolder}
                parentAllowedStudents={classroom.students}
                parentFolderId={null}
                classroomId={classroom._id}
                onFolderCreated={handleAddFolder}
                onFolderUpdated={handleUpdateFolder}
                onFolderDeleted={handleDeleteFolder}
            />


            <div className={styles.foldersBody}>
                {isTeacher && (
                    <div className={styles.folderItem}>
                        <FaFolderPlus
                            className={styles.folderItemImage}
                            onClick={openCreateModal}
                        />
                        <div>Create Folder</div>
                    </div>
                )}
                {folders
                    .filter((folder) => !folder.parentFolder)
                    .map((folder) => (
                        <div key={folder._id} className={styles.folderItem}>
                            <div
                                onClick={() => handleFolderClick(folder)}
                                style={{
                                    textDecoration: 'none',
                                    color: 'inherit',
                                    cursor: 'pointer',
                                }}
                            >
                                <FaFolder className={styles.folderItemImage} />
                                <div className={styles.folderName}>{folder.name}</div>
                            </div>
                            {isTeacher && (
                                <img
                                    src={settingsIcon}
                                    className={styles.optionsButton}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openEditModal(folder);
                                    }}
                                    alt="Edit Folder"
                                />
                            )}
                        </div>
                ))}
            </div>
        </div>
    );
};

export default Folders;
