/**
 * @author Elek Thomas-Toth
 */

import React, { useContext, useState, useEffect } from 'react';
import CanvasContext from '../../context/CanvasContext';
import styles from './styles/ViewHeatmapNode.module.css';

const ViewNode = () => {
    const { selectedNodeId, getNode, heatmapNodeUsers } = useContext(CanvasContext);

    const [node, setNode] = useState(null);
    const [label, setLabel] = useState('');
    const [endpointType, setEndpointType] = useState('');
    const [situationType, setSituationType] = useState('');
    const [fillColor, setFillColor] = useState('#ffffff');
    const [students, setStudents] = useState([]);

    useEffect(() => {
        const fetchNode = async () => {
            if (selectedNodeId) {
                const fetchedNode = getNode(selectedNodeId);
                setNode(fetchedNode);
                setLabel(fetchedNode.data.label || '');
                setEndpointType(fetchedNode.data.endpointType || '');
                setSituationType(fetchedNode.data.situationType || '');
                setFillColor(fetchedNode.data.fillColor || '#ffffff');

                heatmapNodeUsers.forEach(node => {
                    if (node[0] === selectedNodeId) {
                        setStudents(node[1]);
                    }
                });
            }
        };

        fetchNode();
        
    }, [selectedNodeId, getNode]);








    if (!node) {
        return <div>Loading...</div>;
    }

    return (
        <div className={styles.editNode}>
            <h3>Node Info</h3>
            <div className={styles.field}>
                <label>Label: {label}</label>
            </div>

            <div>
                <h2>
                    Users: { students.length }
                </h2>
                {students.map((student, index) => (
                        <li key={index}>{ student } </li>
                    )
                )}

            </div>


 

        </div>
    );
};

export default ViewNode;
