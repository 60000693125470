/**
 * @author Elek Thomas-Toth
 */

import { Handle, Position } from 'reactflow';
import styles from './styles/HeatmapNode.module.css';
import CanvasContext from '../../context/CanvasContext';
import React, { useContext } from 'react';
import 'reactflow/dist/style.css';



const Node = ({ data, isConnectable, selected, showHandles = true, edgeConnecting, id }) => {
    // Determine the node classes based on endpointType and selection
    let nodeClassName = styles.nodeContainer;

    if (selected) {
        nodeClassName += ` ${styles.selected}`;
    }

    if (edgeConnecting) {
        nodeClassName += ` ${styles.edgeConnecting}`;
    }

    if (data.endpointType === 'start') {
        nodeClassName += ` ${styles.startNode}`;
    } else if (data.endpointType === 'end') {
        nodeClassName += ` ${styles.endNode}`;
    } else {
        nodeClassName += ` ${styles.regularNode}`;
    }

    const { heatmapNodes } = useContext(CanvasContext);


    let fillColor = '#fff';

    

    heatmapNodes.forEach(node => {
        if (node[0] === id) {
            fillColor = node[1];
        }
    });

    

    return (
        <div
            className={nodeClassName}
            style={{
                '--fill-color': fillColor,
                '--border-color': data.borderColor || '#ddd',
            }}
        >
            {/* START Label */}
            {data.endpointType === 'start' && (
                <div className={styles.nodeLabel}>START</div>
            )}
            {/* END Label */}
            {data.endpointType === 'end' && (
                <div className={styles.nodeLabel}>END</div>
            )}

            <div className={styles.nodeContent}>
                {showHandles && data.endpointType === 'start' && (
                    <>
                        <Handle
                            type="target"
                            position={Position.Left}
                            className={styles.handleTarget}
                            isConnectable={isConnectable}
                        />
                        {data.label}
                        <Handle
                            type="source"
                            position={Position.Right}
                            id="a"
                            className={styles.handleSource}
                            isConnectable={isConnectable}
                        />
                    </>
                )}

                {showHandles && data.endpointType === 'end' && (
                    <>
                        <Handle
                            type="target"
                            position={Position.Left}
                            className={`${styles.handle} ${styles.handleTarget}`}
                            isConnectable={isConnectable}
                        />
                        {data.label}
                    </>
                )}

                {showHandles && data.endpointType !== 'start' && data.endpointType !== 'end' && (
                    <>
                        <Handle
                            type="target"
                            position={Position.Left}
                            className={styles.handleTarget}
                            isConnectable={isConnectable}
                        />
                        {data.label}
                        <Handle
                            type="source"
                            position={Position.Right}
                            id="b"
                            className={styles.handleSource}
                            isConnectable={isConnectable}
                        />
                    </>
                )}

                {!showHandles && (
                    <>
                        {data.label}
                    </>
                )}
            </div>

        </div>
    );
};

export default Node;
