import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './styles/CreateScenarioModal.module.css';

const CreateScenarioModal = ({ isOpen, onClose }) => {
    const [title, setTitle] = useState('');
    const navigate = useNavigate();

    // If the modal isn't open, don't render anything:
    if (!isOpen) return null;

    const handleCreate = async () => {
        try {
            // 1) Create the scenario with the given title
            const scenarioResponse = await axios.post(
                `${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/`,
                { title }
            );
            const scenarioId = scenarioResponse.data._id;

            // 2) Create the associated canvas
            await axios.post(
                `${process.env.REACT_APP_API_URL_LOCAL}/api/canvas/${scenarioId}`
            );

            // 3) Navigate to the newly created scenario
            navigate(`/create/${scenarioId}`);

            // Close the modal
            onClose();
            setTitle('');
        } catch (err) {
            console.error("Error creating scenario or canvas:", err);
        }
    };

    const handleCancel = () => {
        onClose();
        setTitle('');
    };

    return (
        <div className={styles.overlay}>
            <div className={styles.modal}>
                <h2>Create New Scenario</h2>
                <input
                    type="text"
                    placeholder="Scenario Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className={styles.inputField}
                />
                <div className={styles.buttonRow}>
                    <button onClick={handleCreate} className={styles.createButton}>Create</button>
                    <button onClick={handleCancel} className={styles.cancelButton}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default CreateScenarioModal;
