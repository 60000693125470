import React, { useEffect, useRef, useContext, useMemo } from 'react';
import ReactFlow, { Background, useReactFlow } from 'reactflow';
import 'reactflow/dist/style.css';

import CanvasContext from '../../context/CanvasContext';
import HeatmapNode from './HeatmapNode';
import HeatmapEdge from './HeatmapEdge';
import styles from './styles/HeatmapCanvas.module.css';

const HeatmapCanvas = () => {
    const {
        nodes,
        edges,
        loading,
        selectedNodeId,
        selectedEdgeId,
        onSelectNode,
        onSelectEdge,
        onCanvasClick
    } = useContext(CanvasContext);

    const reactFlowWrapper = useRef(null);
    const { getZoom, setCenter } = useReactFlow();

    /**
     * Centers the view on the start node (if found) or the first node.
     */
    useEffect(() => {
        if (!loading && nodes.length > 0) {
            const startNode = nodes.find((node) => node.data?.endpointType === 'start');
            const nodeToCenter = startNode || nodes[0];

            if (nodeToCenter && nodeToCenter.position) {
                const { x, y } = nodeToCenter.position;
                const zoom = getZoom();
                setCenter(x, y, { zoom, duration: 800 });
            }
        }
    }, [loading, nodes, getZoom, setCenter]);

    /**
     * Handles node click events for highlighting or deselecting a node.
     */
    const onNodeClick = (event, node) => {
        event.stopPropagation();
        if (selectedNodeId === node.id) {
            onCanvasClick();
        } else {
            onSelectNode(node.id);
        }
    };

    /**
     * Handles edge click events for highlighting or deselecting an edge.
     */
    const onEdgeClick = (event, edge) => {
        event.stopPropagation();
        if (selectedEdgeId === edge.id) {
            onCanvasClick();
        } else {
            onSelectEdge(edge.id);
        }
    };

    /**
     * Custom node and edge types for the heatmap.
     */
    const nodeTypes = useMemo(() => ({
        situation: (nodeProps) => {
            const isSelected = selectedNodeId === nodeProps.id;
            return <HeatmapNode {...nodeProps} selected={isSelected} />;
        },
    }), [selectedNodeId]);

    const edgeTypes = useMemo(() => ({
        choice: HeatmapEdge,
        smart: HeatmapEdge,
    }), []);

    if (loading) {
        return <div className={styles.loading}>Loading Canvas...</div>;
    }

    return (
        <div
            className={styles.container}
            ref={reactFlowWrapper}
            onDragOver={(event) => event.preventDefault()}
        >
            <ReactFlow
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                onNodeClick={onNodeClick}
                onEdgeClick={onEdgeClick}
                onPaneClick={onCanvasClick}
                deleteKeyCode={null}
            >
                <Background color="#aaa" gap={16} />
            </ReactFlow>
        </div>
    );
};

export default HeatmapCanvas;
