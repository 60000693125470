import React, { useState } from 'react';
import styles from './styles/UserSubmittedBody.module.css';
import { useScenario } from '../../../context/ScenarioContext';

const UserSubmittedBody = ({ situation, setCurrentSituationId, isScenarioLogged, preview }) => {
    const [activeTab, setActiveTab] = useState('text');
    const [userInput, setUserInput] = useState('');
    const { addDecisionToLog } = useScenario();

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleSkipClick = async () => {
        // Safely find the first choice with a non-null nextSituation
        const nextChoice = situation.choices.find(choice => choice.nextSituation && choice.nextSituation._id);

        if (isScenarioLogged && !preview && nextChoice) {
            await addDecisionToLog({
                type: 'input',
                userInput: '[SKIP]',
                id: nextChoice._id
            });
        }
        if (nextChoice) {
            console.log("SITUATION: ", situation);
            setCurrentSituationId(nextChoice.nextSituation._id);
        } else {
            console.log("No valid next situation found");
        }

        setUserInput('');
    };

    const handleSubmit = async () => {

        // Safely find the first choice with a non-null nextSituation
        const nextChoice = situation.choices.find(choice => choice.nextSituation && choice.nextSituation._id);
        if (isScenarioLogged && !preview && nextChoice) {

            await addDecisionToLog({
                type: 'input',
                userInput,
                id: nextChoice._id
            });
        }

        if (nextChoice) {
            console.log("SITUATION: ", situation);
            setCurrentSituationId(nextChoice.nextSituation._id);
        } else {
            console.log("No valid next situation found");
        }

        setUserInput('');
    };


    return (
        <div className={styles.container}>
            <div className={styles.tabs}>
                <div
                    className={`${styles.tab} ${activeTab === 'text' ? styles.active : ''}`}
                    onClick={() => handleTabClick('text')}
                >
                    Enter Text
                </div>
                <div
                    className={`${styles.tab} ${activeTab === 'audio' ? styles.active : ''}`}
                    onClick={() => handleTabClick('audio')}
                >
                    Record Audio
                </div>
            </div>
            <div className={styles.tabContent}>
                {activeTab === 'text' ? (
                    <textarea
                        className={styles.textarea}
                        placeholder="Enter your text here..."
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                    ></textarea>
                ) : (
                    <div className={styles.recordAudio}>RECORD AUDIO (to be added)</div>
                )}
            </div>

            <div className={styles.buttonContainer}>
                <button className={styles.button} onClick={handleSubmit}>Submit</button>
                <button className={styles.button} onClick={handleSkipClick}>Skip</button>
            </div>
        </div>
    );
};

export default UserSubmittedBody;
