import React, { useCallback, useMemo, useState, useEffect, useRef, useContext } from 'react';
import ReactFlow, { applyNodeChanges, applyEdgeChanges, Controls, Background, useReactFlow } from 'reactflow';
import SituationNode from './SituationNode';
import ErrorOverlay from './ErrorOverlay';
import styles from './styles/Canvas.module.css';
import CanvasContext from '../../../context/CanvasContext';
import Edge from './Edge';

/**
 * Adds a new edge to the list of existing edges.
 *
 * @param {Object} newEdge - The new edge to add.
 * @param {Array<Object>} edges - The current list of edges.
 * @returns {Array<Object>} The updated list of edges.
 */
const addEdge = (newEdge, edges) => {
    return [...edges, newEdge];
};

/**
 * The Canvas component provides the main drag-and-drop interface for managing nodes, edges, and interactions in the canvas.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.setRenderEditSidebar - A function to toggle the edit sidebar.
 * @param {boolean} props.isMobile - Indicates whether the app is in mobile mode.
 * @returns {JSX.Element} The rendered Canvas component.
 */
const Canvas = ({ setRenderEditSidebar, isMobile }) => {
    const {
        nodes, setNodes, edges, setEdges, updateNodes, updateEdges, scenario,
        selectedEdgeId, selectedNodeIds, onSelectNode, onSelectNodes, onSelectEdge,
        onCanvasClick, createSituation, createChoice, getSituation, getChoice, setGetZoomFunc,
        setProjectFunc, setReactFlowWrapper, edgeConnectingNodeId, setEdgeConnectingNodeId,
    } = useContext(CanvasContext);

    const { project, getZoom } = useReactFlow();
    const reactFlowWrapper = useRef(null);
    const lastTapRef = useRef(0); // Tracks last tap time for mobile double-tap detection

    useEffect(() => {
        setGetZoomFunc(() => getZoom);
        setProjectFunc(() => project);
        setReactFlowWrapper(reactFlowWrapper);
    }, [getZoom, project]);

    /**
     * Handles changes to nodes' positions and updates the backend when movement stops.
     *
     * @param {Array<Object>} changes - Changes to node positions.
     */
    const onNodesChange = useCallback(
        (changes) => {
            setNodes((currentNodes) => {
                const updatedNodes = applyNodeChanges(changes, currentNodes);
                const positionChange = changes.find(change => change.type === 'position' && !change.dragging);

                if (positionChange) {
                    updateNodes(updatedNodes);
                }

                return updatedNodes;
            });
        },
        [setNodes, updateNodes]
    );

    /**
     * Handles changes to edges' properties (e.g., creation, deletion).
     *
     * @param {Array<Object>} changes - Changes to edge properties.
     */
    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges]
    );

    /**
     * Creates a new edge connecting two nodes. Updates the backend and local state with the new edge.
     *
     * @async
     * @param {Object} params - The connection parameters, including source and target node IDs.
     */
    const onConnect = useCallback(async (params) => {
        try {
            const { source, target } = params;
            const hasHint = (source === target);
            const newChoice = await createChoice(source, target, hasHint);

            const newEdge = {
                id: newChoice._id,
                source,
                target,
                type: 'choice',
                data: {
                    label: `untitled`,
                    choiceType: hasHint ? 'hint' : '',
                },
            };

            const updatedEdges = addEdge(newEdge, edges);
            setEdges(updatedEdges);
            await getChoice(newEdge.id);
            await updateEdges(updatedEdges);
            if (!isMobile) {
                setRenderEditSidebar(true);
            }
        } catch (error) {
            console.error(`Error creating a new choice: ${error}`);
        }
    }, [createChoice, edges, setEdges, updateEdges, isMobile]);

    /**
     * Handles edge click events to fetch and display edge data.
     *
     * @async
     * @param {Event} event - The edge click event.
     * @param {Object} edge - The edge being clicked.
     */
    const onEdgeClick = async (event, edge) => {
        await getChoice(edge.id);
    };

    /**
     * Handles node click events for both desktop and mobile modes.
     * Supports single-tap and double-tap behavior on mobile for connecting nodes.
     *
     * @param {Event} event - The node click event.
     * @param {Object} node - The node being clicked.
     */
    const onNodeClick = (event, node) => {
        const { id } = node;
        const currentTime = new Date().getTime();
        const tapLength = currentTime - lastTapRef.current;
        const doubleTapThreshold = 300;

        if (isMobile) {
            if (tapLength && tapLength < doubleTapThreshold) {
                if (edgeConnectingNodeId === id) {
                    setEdgeConnectingNodeId(null);
                } else {
                    setEdgeConnectingNodeId(id);
                }
            } else {
                if (edgeConnectingNodeId) {
                    onConnect({ source: edgeConnectingNodeId, target: id });
                    setEdgeConnectingNodeId(null);
                } else {
                    getSituation(id);
                    if (selectedNodeIds.includes(id)) {
                        onCanvasClick();
                    } else {
                        onSelectNode(id);
                    }
                }
            }
            lastTapRef.current = currentTime;
        } else {
            const alreadySelected = selectedNodeIds.includes(id);

            if (event.ctrlKey || event.metaKey) {
                const newSelectedNodeIds = alreadySelected
                    ? selectedNodeIds.filter(nId => nId !== id)
                    : [...selectedNodeIds, id];
                onSelectNodes(newSelectedNodeIds);
            } else {
                getSituation(id);
                if (alreadySelected) {
                    onCanvasClick();
                } else {
                    onSelectNode(id);
                }
            }
        }
    };

    /**
     * Handles drag-and-drop to create nodes at specific canvas positions.
     *
     * @async
     * @param {DragEvent} event - The drag-and-drop event.
     */
    const onDrop = useCallback(async (event) => {
        event.preventDefault();
        const reactFlowBounds = event.target.getBoundingClientRect();
        const nodeType = event.dataTransfer.getData('application/reactflow');
        const zoomLevel = getZoom();

        if (nodeType) {
            try {
                const nodeWidth = 120 * zoomLevel;
                const nodeHeight = 50 * zoomLevel;
                const clientX = event.clientX - reactFlowBounds.left - (nodeWidth / 2);
                const clientY = event.clientY - reactFlowBounds.top - (nodeHeight / 2);
                const position = project({ x: clientX, y: clientY });

                const newSituationData = {
                    isLogged: scenario.isLogged,
                    text: '',
                    isStart: nodeType === 'startNode',
                    isEnd: nodeType === 'endNode',
                };

                const newSituation = await createSituation(newSituationData);

                const endpointType =
                    nodeType === 'startNode' ? 'start' :
                    nodeType === 'endNode' ? 'end' : undefined;

                const newNode = {
                    id: newSituation._id,
                    type: 'situation',
                    position,
                    data: {
                        label: newSituation.text || 'Untitled',
                        endpointType,
                    },
                };

                const updatedNodes = [...nodes, newNode];
                setNodes(updatedNodes);
                updateNodes(updatedNodes);
                setRenderEditSidebar(true);
            } catch (error) {
                console.error(`Error creating a new situation: ${error}`);
            }
        }
    }, [nodes, scenario, updateNodes, getZoom, setNodes, createSituation]);

    const nodeTypes = useMemo(() => ({
        situation: (nodeProps) => {
            const isSelected = selectedNodeIds.includes(nodeProps.id);
            const isEdgeConnecting = edgeConnectingNodeId === nodeProps.id;
            return <SituationNode {...nodeProps} selected={isSelected} edgeConnecting={isEdgeConnecting} />;
        },
    }), [selectedNodeIds, edgeConnectingNodeId]);

    const edgeTypes = useMemo(() => ({
        choice: (edgeProps) => <Edge {...edgeProps} />,
    }), [selectedEdgeId]);

    return (
        <div
            onDrop={onDrop}
            onDragOver={(event) => event.preventDefault()}
            className={styles.container}
            ref={reactFlowWrapper}
        >
            <ReactFlow
                nodes={nodes}
                onNodesChange={onNodesChange}
                edges={edges}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onEdgeClick={onEdgeClick}
                onPaneClick={onCanvasClick}
                onNodeClick={onNodeClick}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                deleteKeyCode={null}
            >
                <Controls />
                <Background color="#aaa" gap={16} />
            </ReactFlow>
            <ErrorOverlay
                onSelectNode={onSelectNode}
                onSelectEdge={onSelectEdge}
            />
        </div>
    );
};

export default Canvas;
