// ClassroomDashboard.jsx
import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { ClassroomContext } from '../../context/ClassroomContext';
import { transformIframes } from '../../utils/utils';
import { Link, useNavigate } from 'react-router-dom';
import styles from './styles/ClassroomDashboard.module.css';
import { FaFolder, FaFolderPlus } from 'react-icons/fa';

const ClassroomDashboard = () => {
    const { classroom, isTeacher } = useContext(ClassroomContext);
    const navigate = useNavigate();

    // State variable for announcements
    const [announcements, setAnnouncements] = useState(classroom?.announcements || []);
    // State for scenarios with logs
    const [loggedScenarios, setLoggedScenarios] = useState([]);
    const [folders, setFolders] = useState(classroom?.folders || []);

    // Update announcements whenever the classroom changes
    useEffect(() => {
        if (classroom) {
            setAnnouncements(classroom.announcements || []);
            setFolders(classroom.folders || []);
        }
    }, [classroom]);

    // Fetch filtered scenarios
    useEffect(() => {
        const fetchLoggedScenarios = async () => {
            if (!classroom?._id) return;
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_API_URL_LOCAL}/api/classrooms/classroom/${classroom._id}/scenarios/logs`
                );
                setLoggedScenarios(res.data);
            } catch (err) {
                console.error('Error fetching scenarios with logs:', err);
            }
        };

        fetchLoggedScenarios();
    }, [classroom]);

    const handleFolderClick = (folder) => {
        // Teachers can always access the folder
        if (isTeacher) {
            navigate(`/my-classrooms/${classroom._id}/f/${folder._id}`);
            return;
        }
    
        // Check access based on privacy settings
        if (folder.isPrivate && !folder.allowedStudents.includes(userId)) {
            alert('You do not have permission to access this folder.');
        } else {
            navigate(`/my-classrooms/${classroom._id}/f/${folder._id}`);
        }
    };

    const lastAnnouncement = announcements.length > 0
        ? announcements[announcements.length - 1]
        : null;

    return (
        <div className={styles.dashboardContainer}>
            {/* Announcements */}
            <div className={styles.sectionBox}>
                <Link
                    to={`/my-classrooms/${classroom?._id}/a`}
                    className={styles.sectionTitleLink}
                >
                    <h2>Announcements</h2>
                </Link>

                <div className={styles.announcementBox}>
                    {lastAnnouncement ? (
                        <>
                            <div className={styles.announcementHeader}>
                                Posted by:{' '}
                                <span className={styles.posterUsername}>
                                    {lastAnnouncement.poster?.username || 'Unknown'}
                                </span>
                            </div>
                            <div
                                className={styles.announcementContent}
                                dangerouslySetInnerHTML={{
                                    __html: transformIframes(lastAnnouncement.text),
                                }}
                            />
                        </>
                    ) : (
                        <p>No announcements yet.</p>
                    )}
                </div>

                {announcements.length > 0 && (
                    <Link
                        to={`/my-classrooms/${classroom?._id}/a`}
                        className={styles.viewAllLink}
                    >
                        View All
                    </Link>
                )}
            </div>

            {/* Logs Section */}
            {isTeacher && (
                <div className={styles.sectionBox}>
                    <Link
                        to={`/my-classrooms/${classroom?._id}/l`}
                        className={styles.sectionTitleLink}
                    >
                        <h2>Logs</h2>
                    </Link>

                    {/* Display each scenario title with navigation onClick */}
                    {loggedScenarios.length > 0 ? (
                        <ul>
                            {loggedScenarios.map((scenario) => (
                                <li
                                    key={scenario._id}
                                    onClick={() =>
                                        navigate(`/my-classrooms/${classroom._id}/l/${scenario._id}`)
                                    }
                                    className={styles.scenarioItem}
                                    style={{ cursor: 'pointer' }} // Add a pointer cursor for better UX
                                >
                                    {scenario.title}
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No scenarios with logs yet.</p>
                    )}
                </div>
            )}


            {/* Folders */}
            <div className={styles.sectionBox}>
                <Link
                    to={`/my-classrooms/${classroom?._id}/f`}
                    className={styles.sectionTitleLink}
                >
                    <h2>Folders</h2>
                </Link>
                <div className={styles.foldersBody}>
                    {folders
                        .filter((folder) => !folder.parentFolder)
                        .map((folder) => (
                            <div key={folder._id} className={styles.folderItem}>
                                <div
                                    onClick={() => handleFolderClick(folder)}
                                    style={{
                                        textDecoration: 'none',
                                        color: 'inherit',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <FaFolder className={styles.folderItemImage} />
                                    <div className={styles.folderName}>{folder.name}</div>
                                </div>
                            </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ClassroomDashboard;
