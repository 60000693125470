import React, { useState, useEffect } from 'react';
import styles from './styles/EditAnnouncementModal.module.css';
import TextEditor from '../texteditor/TextEditor';


const EditAnnouncementModal = ({ isOpen, onClose, announcement, handleStopEditing }) => {
    const [editedText, setEditedText] = useState('');

    useEffect(() => {
        if (announcement?.text) {
            setEditedText(announcement.text);
        }
    }, [announcement]);

    const handleSave = () => {
        if (editedText.trim() === '') return;
        handleStopEditing(announcement._id, editedText);
        onClose();
    };

    const handleCancel = () => {
        onClose();
    };

    // Separate handler for the main text editor
    const handleTextEditorChange = (data) => {
        setEditedText(data);
    };

    if (!isOpen) return null;

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <h2>Edit Announcement</h2>
                <TextEditor
                    initialData={editedText}
                    onChange={handleTextEditorChange}
                />
                <div className={styles.buttonGroup}>
                    <button onClick={handleSave} className={styles.saveButton}>
                        Save
                    </button>
                    <button onClick={handleCancel} className={styles.cancelButton}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EditAnnouncementModal;
