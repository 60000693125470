import React, { useContext } from 'react';
import styles from './styles/HeatmapOptions.module.css';
import CanvasContext from '../../context/CanvasContext.js';
import { FaSearch, FaExclamationCircle, FaCheckCircle } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import { checkValidity } from '../../utils/utils.js';
import { useNavigate } from 'react-router-dom';

/**
 * A component for creating new situations in the canvas. 
 * Supports both drag-and-drop (non-mobile) and click-to-create (mobile) modes.
 *
 * @param {Object} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const HeatmapOptions = () => {
    const { scenario, graphErrors, setGraphErrors, nodes, edges, setIsGeneratingLogs, onCanvasClick } = useContext(CanvasContext);

    const navigate = useNavigate();


    return (
        <>

            <div className={styles.container}>
                <div className={styles.header}>
                    <h2>{scenario?.title || 'Untitled Scenario'}</h2>
                </div>
                <div className={styles.header}>
                    <h2>Heatmap key</h2>
                </div>
                <div className={styles.heatmapkey}></div>

                <div className= {styles.heatmaplabels}>
                    <span className={styles.label}>0%</span>
                    <span className={styles.label}>50%</span>
                    <span className={styles.label}>100%</span>
                </div>
                <div className={styles.header}>
                    <h2>Explanation</h2>
                </div>
                <div className={styles.header}>
                    <p>
                        This heatmap analyzes the most common paths taken by your students.
                        colors are calculated based on how many students entered each node.
                        Red means that a node was entered more than any other node. Yellow corresponds
                        to a node being entered about 75% as much as the most common node, green 50%, and blue 25%.
                        A white node means the node was never entered.
                    </p>
                    <h2>How to read this heatmap</h2>
                    <p>
                        Nodes at the end of the spectrums such as yellow-red and white-blue nodes likely
                        contain the most relevant information. These colors indicate that students are either
                        commonly entering these nodes or commonly avoiding them.
                    </p>
                </div>


            </div>
            <Tooltip
                id="tooltip"
                place="right"
                type="dark"
                effect="solid"
                style={{ zIndex: 9999 }}
            />
        </>
    );
};

export default HeatmapOptions;
