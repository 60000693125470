import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles/MyClassroomsGridItem.module.css';

const MyClassroomsGridItem = ({
    classroom,
    classroomImages,
    index,
    openedDropdown,
    setOpenedDropdown,
    openEditModal
}) => {
    return (
        <Link to={`/my-classrooms/${classroom._id}/d`} style={{ textDecoration: 'none', color: 'inherit', width: 'fit-content' }}>
            <div className={styles.classroomItem}>
                <img
                    className={styles.classroomImage}
                    src={classroom.image || classroomImages[index % classroomImages.length]}
                    alt="Classroom Icon"
                />
                <div className={styles.classroomName}>{classroom.name}</div>
                <button className={styles.optionsButton} onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setOpenedDropdown(classroom._id === openedDropdown ? null : classroom._id);
                }} />
                {openedDropdown === classroom._id && (
                    <div className={styles.optionsDropdown}>
                        <div className={styles.optionsDropdownItem} onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            openEditModal(classroom);
                            setOpenedDropdown(false);
                        }}>
                            Edit
                        </div>
                    </div>
                )}
            </div>
        </Link>
    );
};

export default MyClassroomsGridItem;
