import React from 'react';
import styles from './styles/Footer.module.css';
import logo from '../../../images/reverseLogo.png';

const Footer = () => {
    return (
        <div className={styles.footer}>
            <div className={styles.logo}>
                <img src={logo} className={styles.logoImage} alt='logo' />
                <div className={styles.footerInfo}>
                    <p>&copy; AlphaScenario</p>
                </div>
            </div>

            <div className={styles.learn}>
                {/* <div className={styles.footerTitle}>
                    <p>Learn</p>
                </div> */}

                <div className={styles.siteMap}>
                    {/* First column */}
                    {/* <div className={styles.footerInfo}>
                        <a 
                            href="#featured" 
                            onClick={() => footerClick('Featured')} 
                            className={styles.link}
                        >
                            Featured
                        </a>
                        <a 
                            href="#mostViewed" 
                            onClick={() => footerClick('Most Viewed')} 
                            className={styles.link}
                        >
                            Most Viewed
                        </a>
                        <a 
                            href="#education" 
                            onClick={() => footerClick('Education')} 
                            className={styles.link}
                        >
                            Education
                        </a>
                    </div> */}
                    {/* Second column */}
                    {/* <div className={`${styles.footerInfo} ${styles.footerInfo2}`}>
                        <a 
                            href="#entertainment" 
                            onClick={() => footerClick('Entertainment')} 
                            className={styles.link}
                        >
                            Entertainment
                        </a>
                        <a 
                            href="#recentlyPublished" 
                            onClick={() => footerClick('Recently Published')} 
                            className={styles.link}
                        >
                            Recently Published
                        </a>
                        <a 
                            href="#recommended" 
                            onClick={() => footerClick('Recommended')} 
                            className={styles.link}
                        >
                            Recommended
                        </a>
                    </div> */}
                </div>
            </div>

            <div className={styles.about}>
                <div className={styles.footerTitle}>
                    <p>About</p>
                </div>
                <div className={styles.footerInfo}>
                    <a 
                        href="#about" 
                        // onClick={() => footerClick('About')} 
                        className={styles.link}
                    >
                        About
                    </a>
                    <a 
                        href="#privacyPolicy" 
                        // onClick={() => footerClick('Privacy Policy')} 
                        className={styles.link}
                    >
                        Privacy Policy
                    </a>
                </div>
            </div>

            <div className={styles.contact}>
                <div className={styles.footerTitle}>
                    <p>Contact</p>
                </div>
                <div className={styles.footerInfo}>
                    <p>admin@alphascenario.com</p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
