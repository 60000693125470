import React, { useEffect, useState } from 'react';
import styles from './styles/FrontPage.module.css';
import templateImage from '../../../images/logo_teal_background.png';
// If you want icons, install and import from react-icons (optional)
import { FaCogs, FaMobileAlt, FaLaptopCode, FaShareSquare, FaPaintBrush, FaChartBar, FaSearch,
    FaBookOpen, FaComment, FaCommentDots, FaPlayCircle, FaGraduationCap, FaBuilding,
 } from 'react-icons/fa';
import AuthModal from '../../auth/AuthModal';
import Footer from './Footer';


const FrontPage = () => {

    const [showModal, setShowModal] = useState(false);
    const [authModalState, setAuthModalState] = useState('sign up');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const openSignUpModal = () => {
        setShowModal(!showModal);
        setAuthModalState('sign up');
    }

    return (
        <>
            <div className={styles.container}>
                {/* Hero Section */}
                <section className={styles.heroSection}>
                    <h1>Interactive Content</h1>
                    <h1>Tailored Experiences</h1>
                    <p>Your go-to platform for scenario-based creation and collaboration.</p>
                    <button className={styles.heroButton} onClick={openSignUpModal}>Get Started</button>
                </section>

                {/* About Section */}
                <section className={styles.aboutSection}>
                    <img
                        src={templateImage}
                        alt="AlphaScenario Preview"
                        className={styles.aboutImage}
                    />
                    <div className={styles.aboutText}>
                        <h2>About AlphaScenario</h2>
                        <p>
                            AlphaScenario is a platform that makes it effortless to build and manage
                            interactive scenarios. Create robust visual flows, collaborate with
                            teammates in real-time, and bring your product ideas to life faster than
                            ever before.
                        </p>
                    </div>
                </section>

                {/* Features Sections for Creators and Viewers */}
                <section className={styles.featuresSectionCreators}>
                    <h2 className={styles.sectionTitle}>Features for Creators</h2>
                    <div className={styles.featuresGrid}>

                        {/* Effortless Creation */}
                        <div className={styles.featureItem}>
                            <FaCogs className={styles.icon} />
                            <h3>Effortless Creation</h3>
                            <p>
                                Quickly build scenarios with our intuitive drag-and-drop interface. 
                                Customize each node with text, images, audio, or video—or any combination 
                                you need—no steep learning curve required.
                            </p>
                        </div>

                        {/* Flexible Publishing */}
                        <div className={styles.featureItem}>
                            <FaShareSquare className={styles.icon} />
                            <h3>Flexible Publishing</h3>
                            <p>
                                Publish your scenarios for everyone to discover or limit access 
                                via private links or password protection. Share your interactive 
                                stories on your own terms.
                            </p>
                        </div>

                        {/* Rich Customization */}
                        <div className={styles.featureItem}>
                            <FaPaintBrush className={styles.icon} />
                            <h3>Rich Customization</h3>
                            <p>
                                Personalize each node’s visuals, combine different media formats, 
                                and design branching paths to create unique user experiences 
                                that truly represent your content.
                            </p>
                        </div>

                        {/* Engagement Insights */}
                        <div className={styles.featureItem}>
                            <FaChartBar className={styles.icon} />
                            <h3>Engagement Insights</h3>
                            <p>
                                Track and analyze user behavior with heatmaps that highlight which 
                                nodes and edges attract the most attention, so you can continually 
                                refine and improve.
                            </p>
                        </div>
                    </div>
                </section>

                <section className={styles.featuresSectionViewers}>
                    <h2 className={styles.sectionTitle}>Features for Viewers</h2>
                    <div className={styles.featuresGrid}>

                        {/* Browse & Discover */}
                        <div className={styles.featureItem}>
                            <FaSearch className={styles.icon} />
                            <h3>Browse & Discover</h3>
                            <p>
                                Find interesting scenarios by searching tags, titles, or creator names.
                                Explore a vast library of content to learn, be entertained, or simply
                                get inspired.
                            </p>
                        </div>

                        {/* Immersive Experiences */}
                        <div className={styles.featureItem}>
                            <FaBookOpen className={styles.icon} />
                            <h3>Immersive Experiences</h3>
                            <p>
                                Delve into engaging interactive stories and educational material.
                                Let the branching paths spark your curiosity and keep you coming back
                                for more.
                            </p>
                        </div>

                        {/* Direct Feedback */}
                        <div className={styles.featureItem}>
                            <FaComment className={styles.icon} />
                            <h3>Direct Feedback</h3>
                            <p>
                                Comment on specific parts of a scenario or send suggestions to creators
                                in real-time. Your voice shapes the community, helps creators improve,
                                and fosters collaboration.
                            </p>
                        </div>

                        {/* Adaptive Responses */}
                        <div className={styles.featureItem}>
                            <FaCommentDots className={styles.icon} />
                            <h3>Adaptive Responses</h3>
                            <p>
                                Watch scenarios evolve based on user input. Creators can address
                                feedback within the scenario itself, adding new branches or updates
                                that respond to your suggestions.
                            </p>
                        </div>

                    </div>
                </section>

                {/* Industry Section */}
                <section className={styles.industrySection}>
                    <h2 className={styles.sectionTitle}>Tailored Solutions by Industry</h2>
                    <p className={styles.sectionSubtitle}>
                        AlphaScenario adapts to a wide range of use cases, from 
                        immersive entertainment experiences to educational and 
                        enterprise applications.
                    </p>

                    <div className={styles.industryGrid}>
                        {/* Entertainment */}
                        <div className={styles.industryCard}>
                            <FaPlayCircle className={styles.industryIcon} />
                            <h3>Entertainment</h3>
                            <p>
                                Bring branching narratives to life with ease, whether you’re 
                                a game developer or a storyteller creating interactive 
                                visual novels. Manage complex plotlines, multiple endings, 
                                and dynamic user choices to delight and captivate your audience.
                            </p>
                        </div>

                        {/* Education */}
                        <div className={styles.industryCard}>
                            <FaGraduationCap className={styles.industryIcon} />
                            <h3>Education</h3>
                            <p>
                                Empower educators to create adaptive learning content, track 
                                student progress, and manage classrooms effectively. Use 
                                real-time insights to enhance teaching methods, improve 
                                student outcomes, and foster deeper engagement with 
                                interactive lessons.
                            </p>
                        </div>

                        {/* Business */}
                        <div className={styles.industryCard}>
                            <FaBuilding className={styles.industryIcon} />
                            <h3>Business</h3>
                            <p>
                                Craft engaging training simulations, adaptive advertisements, 
                                and personalized customer journeys. Boost conversion rates 
                                and sales with interactive product demos, streamlined 
                                onboarding flows, and memorable brand experiences—no coding 
                                expertise required.
                            </p>
                        </div>
                    </div>
                </section>


                {/* CTA Section */}
                <section className={styles.ctaSection}>
                    <h2>Ready to Get Started?</h2>
                    <p>Join AlphaScenario and transform the way you design interactive experiences.</p>
                    <button className={styles.ctaButton} onClick={openSignUpModal}>Sign Up Now</button>
                </section>
                <Footer/>
            </div>
            {showModal && (
                <AuthModal 
                    showModal={showModal} setShowModal={setShowModal} 
                    authModalState={authModalState} setAuthModalState={setAuthModalState}
                />
            )}
        </>
    );
};

export default FrontPage;
